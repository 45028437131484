/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PeriodicContractFinancialCostType = 'ARBITRAGE' | 'COMMISSION' | 'SERVICE_MANAGEMENT';

export const PeriodicContractFinancialCostType = {
    ARBITRAGE: 'ARBITRAGE' as PeriodicContractFinancialCostType,
    COMMISSION: 'COMMISSION' as PeriodicContractFinancialCostType,
    SERVICEMANAGEMENT: 'SERVICE_MANAGEMENT' as PeriodicContractFinancialCostType
};

