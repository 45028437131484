/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The state of the property
 */
export type PropertyState = 'ACTIVE' | 'SUSPENDED' | 'ARCHIVED';

export const PropertyState = {
    ACTIVE: 'ACTIVE' as PropertyState,
    SUSPENDED: 'SUSPENDED' as PropertyState,
    ARCHIVED: 'ARCHIVED' as PropertyState
};

