/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The field to sort the filtered budget analysis by
 */
export type BudgetAnalysisSortField = 'RENTAL_INCOME_ACTUAL' | 'OCCUPANCY_ACTUAL';

export const BudgetAnalysisSortField = {
    RENTALINCOMEACTUAL: 'RENTAL_INCOME_ACTUAL' as BudgetAnalysisSortField,
    OCCUPANCYACTUAL: 'OCCUPANCY_ACTUAL' as BudgetAnalysisSortField
};

