import {Injectable} from '@angular/core';
import {environment} from '@env/environment';

@Injectable({ providedIn: 'root' })
export class RedirectService {

  // store the URL so we can redirect after logging in
  private redirectUrl: string = environment.adminRoot;

  getRedirectUrl(): string {
    console.log(`Redirecting to ${this.redirectUrl}`);
    return this.redirectUrl;
  }

  setRedirectUrl(redirectUrl: string) {
    if (redirectUrl.includes('login')) {
      console.log(`Skipped setting redirect url to ${redirectUrl}`);
    } else {
      console.log(`Setting redirect url to ${this.redirectUrl}`);
      this.redirectUrl = redirectUrl;
    }
  }
}
