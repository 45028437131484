/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReservationLeadTimeAnalysis { 
    /**
     * the date/month the reservations will be checking in
     */
    date?: string;
    /**
     * the number of bookings checking in on \"date\" that have a lead time of 7 days or less
     */
    lead_time_less_than_7_days?: number;
    /**
     * the number of bookings checking in on \"date\" that have a lead time between 7 and 30 days
     */
    lead_time_between_7_days_and_30_days?: number;
    /**
     * the number of bookings checking in on \"date\" that have a lead time between 30 and 60 days
     */
    lead_time_between_30_days_and_60_days?: number;
    /**
     * the number of bookings checking in on \"date\" that have a lead time of more than 60 days
     */
    lead_time_more_than_60_days?: number;
}

