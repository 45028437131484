/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ZzzingServiceType } from './zzzingServiceType';
import { CommercialDocument } from './commercialDocument';
import { GuestServiceBookingItemSummary } from './guestServiceBookingItemSummary';
import { PropertyShortRef } from './propertyShortRef';
import { ZzzingServiceFulfilmentDetails } from './zzzingServiceFulfilmentDetails';
import { GuestServiceBookingStatus } from './guestServiceBookingStatus';
import { GuestServiceBookingCancellationInfo } from './guestServiceBookingCancellationInfo';


export interface GuestZzzingServiceBookingSummary { 
    id?: string;
    reservation_id?: string;
    customer_id?: string;
    /**
     * The name of the customer that bought the service
     */
    customer_name?: string;
    property_short_ref?: PropertyShortRef;
    state?: GuestServiceBookingStatus;
    creation_date?: string;
    service_date?: string;
    time?: string;
    description?: string;
    service_type?: ZzzingServiceType;
    allow_entry?: boolean;
    items_summary?: Array<GuestServiceBookingItemSummary>;
    confirmation_code?: string;
    phone_number?: string;
    number_of_units?: number;
    total_price?: number;
    additional_comments?: string;
    /**
     * Optional url for an image that represents the booking
     */
    image_url?: string;
    /**
     * The commercial documents related to this booking
     */
    commercial_documents?: Array<CommercialDocument>;
    cancellation_info?: GuestServiceBookingCancellationInfo;
    fulfilment_details?: ZzzingServiceFulfilmentDetails;
}

