import {Component} from '@angular/core';

@Component({
  selector: 'app-boxes-loading',
  templateUrl: './boxes-loading.component.html',
  styleUrls: ['./boxes-loading.component.scss']
})
export class BoxesLoadingComponent {

  constructor() {
  }

}
