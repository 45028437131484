import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

/**
 * A pipe to be used to format a date time in 'dd-MM-yyyy HH:mm' format.
 */
@Pipe({
  name: 'dmy_datetime'
})
export class Dmy_datetimePipe implements PipeTransform {

  private static DATE_FORMAT = 'dd-MM-yyyy HH:mm';

  constructor(private datePipe: DatePipe) {}

  transform(value: any): string {
    return value ? this.datePipe.transform(new Date(value), Dmy_datetimePipe.DATE_FORMAT) : null;
  }

}
