/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The field to sort the contract periodic entries by
 */
export type PeriodicContractFinancialSortField = 'ID' | 'ENTRY_DATE' | 'BILLING_PERIOD_END_DATE' | 'PROCESSED_DATE';

export const PeriodicContractFinancialSortField = {
    ID: 'ID' as PeriodicContractFinancialSortField,
    ENTRYDATE: 'ENTRY_DATE' as PeriodicContractFinancialSortField,
    BILLINGPERIODENDDATE: 'BILLING_PERIOD_END_DATE' as PeriodicContractFinancialSortField,
    PROCESSEDDATE: 'PROCESSED_DATE' as PeriodicContractFinancialSortField
};

