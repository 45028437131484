/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetAnalysisSortField } from './budgetAnalysisSortField';
import { SortOrder } from './sortOrder';
import { BudgetAnalysisType } from './budgetAnalysisType';


export interface InventoryBudgetAnalysisFilterAllOf { 
    type: BudgetAnalysisType;
    page_number?: number;
    page_size?: number;
    sort_field?: BudgetAnalysisSortField;
    sort_order?: SortOrder;
}

