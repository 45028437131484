/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PinCodeType = 'UNKNOWN' | 'TEMPORARY' | 'PERMANENT';

export const PinCodeType = {
    UNKNOWN: 'UNKNOWN' as PinCodeType,
    TEMPORARY: 'TEMPORARY' as PinCodeType,
    PERMANENT: 'PERMANENT' as PinCodeType
};

