/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The field to sort the filtered audit logs by
 */
export type AuditLogSortField = 'ID';

export const AuditLogSortField = {
    ID: 'ID' as AuditLogSortField
};

