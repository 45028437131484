/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractBillingCycle } from './contractBillingCycle';


export interface CommissionBillingTerms { 
    billing_cycle?: ContractBillingCycle;
    /**
     * Commission percentage of gross rental
     */
    commission_percentage?: number;
    /**
     * Payment fee percentage of gross rental
     */
    payment_fee_percentage?: number;
    /**
     * Daily subscription fee
     */
    daily_subscription_fee?: number;
}

