import { Configuration } from '@api/configuration';
import { environment } from '@env/environment';

export const ApiConfigFactory = () => {
  return new Configuration({
    basePath: environment.apiUrl
  });
};

export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const MOMENT_API_DATE_FORMAT = 'YYYY-MM-DD';
export const MOMENT_API_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
