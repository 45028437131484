<div class="mb-3 position-relative">
  <textarea [formControl]="formControl" class="form-control" [maxLength]="to.max || 500" [rows]="to.rows || 5" #formTextArea></textarea>
  <div class="progress-bar-circle bottom-right m-2" *ngIf="to.showCounter && to.max">
    <div class="progressbar-text text-small">{{to.max - formControl.value.length}}</div>
    <round-progress [current]="formControl.value.length"
                    [max]="to.max"
                    [responsive]="true"
                    [duration]="500"
                    [animation]="'easeInOutQuart'"
                    [animationDelay]="0"
                    [radius]="50"
                    [rounded]="true"
                    [stroke]="4">
    </round-progress>
  </div>
</div>
