/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FinancialPerformanceStatistic { 
    /**
     * The statistic value when considering completed reservations only
     */
    actual?: number;
    /**
     * The statistic value when considering completed reservations as well as reservations that are yet to be completed
     */
    projected?: number;
}

