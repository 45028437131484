/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type IncomeAndExpenseEntryType = 'RENTAL_INCOME' | 'TOURIST_TAX_INCOME' | 'OTHER_INCOME' | 'VH_SERVICE_CHARGE_EXPENSE' | 'OTA_COSTS_DISBURSEMENT_EXPENSE' | 'PROPERTY_MAINTENANCE_EXPENSE' | 'OTHER_EXPENSE';

export const IncomeAndExpenseEntryType = {
    RENTALINCOME: 'RENTAL_INCOME' as IncomeAndExpenseEntryType,
    TOURISTTAXINCOME: 'TOURIST_TAX_INCOME' as IncomeAndExpenseEntryType,
    OTHERINCOME: 'OTHER_INCOME' as IncomeAndExpenseEntryType,
    VHSERVICECHARGEEXPENSE: 'VH_SERVICE_CHARGE_EXPENSE' as IncomeAndExpenseEntryType,
    OTACOSTSDISBURSEMENTEXPENSE: 'OTA_COSTS_DISBURSEMENT_EXPENSE' as IncomeAndExpenseEntryType,
    PROPERTYMAINTENANCEEXPENSE: 'PROPERTY_MAINTENANCE_EXPENSE' as IncomeAndExpenseEntryType,
    OTHEREXPENSE: 'OTHER_EXPENSE' as IncomeAndExpenseEntryType
};

