import {Component, Input, OnInit} from '@angular/core';
import {Property} from '@api/model/property';
import {Reservation} from '@api/model/reservation';
import {PropertyInventoryService} from '@api/api/propertyInventory.service';
import {ReservationsService} from '@api/api/reservations.service';
import {switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-reservation-summary',
  styleUrls: ['./reservation-summary.component.scss'],
  templateUrl: './reservation-summary.component.html'
})
export class ReservationSummaryComponent implements OnInit {

  @Input() reservationId;

  reservation: Reservation;
  property: Property;

  constructor(private reservationsService: ReservationsService,
              private propertyService: PropertyInventoryService) {
  }

  ngOnInit(): void {
    this.reservationsService.getReservationById(this.reservationId)
      .pipe(
        tap(reservation => this.reservation = reservation),
        switchMap(reservation => this.propertyService.getPropertyById(reservation.property_short_ref.id))
      ).subscribe(property => this.property = property);
  }

}
