/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PinCodeFilter { 
    /**
     * The page number of the result (the first page is 0)
     */
    page_number?: number;
    /**
     * The number of items returned in each page
     */
    page_size?: number;
    lock_id?: string;
}

