import {Component} from '@angular/core';

@Component({
  selector: 'app-application-menu',
  templateUrl: './application-menu.component.html'
})

export class ApplicationMenuComponent {
  isOpen = false;

  constructor() {
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
