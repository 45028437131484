/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TaxCategoryCode } from './taxCategoryCode';


export interface UpdateReservationSalesChannelParams { 
    applicable_tax_category?: TaxCategoryCode;
    payment_fee_percentage_on_gross_amount?: number;
    commission_percentage_rent?: number;
    commission_percentage_cleaning?: number;
    commission_percentage_tourist_tax?: number;
    commission_percentage_cancellation?: number;
    /**
     * Owner bookings are only allowed from certain sales channels. This indicates which channel supports it
     */
    allow_owner_bookings?: boolean;
    /**
     * Long lets are only allowed from certain sales channels. This indicates which channel supports it
     */
    allow_long_lets?: boolean;
}

