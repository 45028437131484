/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PropertyAmenityRef } from './propertyAmenityRef';
import { PropertyTagRef } from './propertyTagRef';
import { CheckInInfo } from './checkInInfo';
import { PropertyAdditionalDetails } from './propertyAdditionalDetails';


export interface PropertyUpdateParams { 
    additional_details?: PropertyAdditionalDetails;
    check_in_info?: CheckInInfo;
    target_yield?: Array<number>;
    grouping?: string;
    /**
     * A friendly reference provided by the landowners to identify the property for themselves
     */
    owner_property_reference?: string;
    /**
     * List of amenities defined by ID to be associated with the property
     */
    amenities?: Array<PropertyAmenityRef>;
    /**
     * List of tags defined by ID to be associated with the property
     */
    tags?: Array<PropertyTagRef>;
}

