import {LoginSuccessResult} from '@api/model/loginSuccessResult';

export const AUTH_TOKEN_KEY = 'token';

export function createSession(params: Partial<LoginSuccessResult>) {
  return {
    ...params,
    access_token: localStorage.getItem(AUTH_TOKEN_KEY)
  } as LoginSuccessResult;
}
