/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReservationState } from './reservationState';
import { LongLetReservationDetails } from './longLetReservationDetails';
import { ReservationFinancialDetailsSummary } from './reservationFinancialDetailsSummary';
import { ReservationPinStatus } from './reservationPinStatus';
import { BasicLandOwnerShortRef } from './basicLandOwnerShortRef';
import { ReservationGuestIssueDetails } from './reservationGuestIssueDetails';
import { ReservationType } from './reservationType';
import { PropertyShortRef } from './propertyShortRef';
import { BasicContractShortRef } from './basicContractShortRef';
import { Room } from './room';


export interface ReservationSummary { 
    id?: string;
    type?: ReservationType;
    booking_ref?: string;
    channel_manager_id?: string;
    channel_manager_key?: string;
    /**
     * The reservation id given by the sales channel
     */
    sales_channel_reservation_id?: string;
    /**
     * The stay id given by the sales channel in case of multiple stays for a single reservation
     */
    stay_id?: string;
    sales_channel_id?: string;
    sales_channel_name?: string;
    state?: ReservationState;
    property_short_ref?: PropertyShortRef;
    contract_short_ref?: BasicContractShortRef;
    owner_short_ref?: BasicLandOwnerShortRef;
    booking_date?: string;
    cancellation_date?: string;
    check_in_date?: string;
    check_in_time?: string;
    check_out_date?: string;
    number_of_nights?: number;
    number_of_guests?: number;
    guest_name?: string;
    guest_surname?: string;
    guest_phone_number?: string;
    guest_email_address?: string;
    customer_id?: string;
    owner_booking?: boolean;
    pin_status?: ReservationPinStatus;
    financial_details?: ReservationFinancialDetailsSummary;
    custom_fields?: { [key: string]: string; };
    rooms?: Array<Room>;
    long_let_details?: LongLetReservationDetails;
    guest_issues?: Array<ReservationGuestIssueDetails>;
}

