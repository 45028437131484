/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CheckInDetails } from './checkInDetails';


export interface CheckInInfo { 
    separate_common_entrance?: boolean;
    common_entrance_check_in_details?: CheckInDetails;
    property_check_in_details?: CheckInDetails;
}

