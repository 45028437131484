import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BoxesLoadingComponent} from '@app/components/boxes-loading/boxes-loading.component';
import {ColoredNumberDirective} from '@app/components/colored-number/colored-number.directive';
import {ConfirmationModalComponent} from '@app/components/confirmation-modal/confirmation-modal.component';
import {NoResultsFoundComponent} from '@app/components/no-results-found/no-results-found.component';
import {PipesModule} from '@app/pipes/pipes.module';
import {TranslateModule} from '@ngx-translate/core';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ErrorComponent} from '../views/error/error.component';
import {ReservationSummaryComponent} from '@app/components/reservation-summary/reservation-summary.component';
import {BootstrapModule} from '@app/components/bootstrap/bootstrap.module';
import {ImagePlaceholderModule} from '@app/components/images/image-placeholder.module';

@NgModule({
  declarations: [
    ErrorComponent,
    NoResultsFoundComponent,
    ColoredNumberDirective,
    BoxesLoadingComponent,
    ConfirmationModalComponent,
    ReservationSummaryComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    PerfectScrollbarModule,
    PipesModule,
    BootstrapModule,
    ImagePlaceholderModule
  ],
  exports: [
    PerfectScrollbarModule,
    RouterModule,
    ErrorComponent,
    TranslateModule,
    CommonModule,
    PipesModule,
    NoResultsFoundComponent,
    ColoredNumberDirective,
    BoxesLoadingComponent,
    ConfirmationModalComponent,
    ReservationSummaryComponent
  ]
})
export class SharedModule {
}
