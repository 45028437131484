import { environment } from 'src/environments/environment';


export const getThemeColor = () => {
    let color = environment.defaultColor;
    try {
        color = localStorage.getItem(environment.themeColorStorageKey) || environment.defaultColor
    } catch (error) {
        console.log(">>>> src/app/utils/util.js : getThemeColor -> error", error)
        color = environment.defaultColor
    }
    return color;
}
export const setThemeColor = (color) => {
    try {
        if (color) {
            localStorage.setItem(environment.themeColorStorageKey, color);
            return setActiveTheme(color);
        } else {
            localStorage.removeItem(environment.themeColorStorageKey)
        }
    } catch (error) {
        console.log(">>>> src/app/utils/util.js : setThemeColor -> error", error)
    }
}
export const getThemeRadius = () => {
    let radius = 'rounded';
    try {
        radius = localStorage.getItem(environment.themeRadiusStorageKey) || 'rounded';
    } catch (error) {
        console.log(">>>> src/app/utils/util.js : getThemeRadius -> error", error)
        radius = 'rounded'
    }
    return radius;
}
export const setThemeRadius = (radius) => {
    try {
        localStorage.setItem(environment.themeRadiusStorageKey, radius);
    } catch (error) {
        console.log(">>>> src/app/utils/util.js : setThemeRadius -> error", error)
    }
}

export const getThemeLang = () => {
    let lang = 'en-US';
    try {
        lang = localStorage.getItem('theme_lang') || 'en-US';
    } catch (error) {
        console.log(">>>> src/app/utils/util.js : getThemeLang -> error", error)
        lang = 'en-US'
    }
    return lang;
}
export const setThemeLang = (lang) => {
    try {
        localStorage.setItem('theme_lang', lang);
    } catch (error) {
        console.log(">>>> src/app/utils/util.js : setThemeLang -> error", lang)
    }
}

/**
 * Sets the active stylesheet in the document head. The theme stylesheets that
 * may be loaded here are those that are declared in the angular.json and which
 * are bundled and served lazily as css (i.e. dark.zzzing.css, light.zzzing.css).
 *
 * @param color the color theme.
 * @returns {HTMLElement} the stylesheet element containing the styles for the active theme.
 */
function setActiveTheme(color) {
    const themeElement = document.getElementById('theme');

    if (themeElement) {
        themeElement.href = `${color}.css`;
        return themeElement;
    } else {
        const newThemeElement = document.createElement('link');
        newThemeElement.id = 'theme';
        newThemeElement.rel = 'stylesheet';
        newThemeElement.href = `${color}.css`;

        const head = document.head;
        head.appendChild(newThemeElement);

        return newThemeElement;
    }
}
