/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type GuestZzzingServicesBookingsSortField = 'ID' | 'BOOKING_DATE' | 'SERVICE_DATE';

export const GuestZzzingServicesBookingsSortField = {
    ID: 'ID' as GuestZzzingServicesBookingsSortField,
    BOOKINGDATE: 'BOOKING_DATE' as GuestZzzingServicesBookingsSortField,
    SERVICEDATE: 'SERVICE_DATE' as GuestZzzingServicesBookingsSortField
};

