/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The field to sort the filtered contract reservations by
 */
export type ContractReservationSortField = 'RESERVATION_ID' | 'ENTRY_DATE';

export const ContractReservationSortField = {
    RESERVATIONID: 'RESERVATION_ID' as ContractReservationSortField,
    ENTRYDATE: 'ENTRY_DATE' as ContractReservationSortField
};

