import {Component} from '@angular/core';
import {ValueComponent} from '@forms/primitives/value/value.component';

@Component({
  selector: 'formly-json-value',
  template: '<div *ngFor="let key of valueIterable;" class="mb-1">' +
    '   <span class="m-2 font-weight-bold">{{key}}</span><span class="m-2">{{value[key]}}</span>' +
    '</div>',
})
export class JsonValueComponent extends ValueComponent {

  get valueIterable() {
    if (this.value) {
      return Object.keys(this.value);
    } else {
      return [];
    }
  }

}
