/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PropertyMaintenanceExpenseType = 'PROPERTY_ONBOARDING' | 'REFURBISHMENT' | 'PLANNED_MAINTENANCE' | 'REACTIVE_MAINTENANCE' | 'INVENTORY_ITEMS' | 'INSURANCE_PACKAGE';

export const PropertyMaintenanceExpenseType = {
    PROPERTYONBOARDING: 'PROPERTY_ONBOARDING' as PropertyMaintenanceExpenseType,
    REFURBISHMENT: 'REFURBISHMENT' as PropertyMaintenanceExpenseType,
    PLANNEDMAINTENANCE: 'PLANNED_MAINTENANCE' as PropertyMaintenanceExpenseType,
    REACTIVEMAINTENANCE: 'REACTIVE_MAINTENANCE' as PropertyMaintenanceExpenseType,
    INVENTORYITEMS: 'INVENTORY_ITEMS' as PropertyMaintenanceExpenseType,
    INSURANCEPACKAGE: 'INSURANCE_PACKAGE' as PropertyMaintenanceExpenseType
};

