import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from '@app/app.module';
import {environment} from '@env/environment';
import {getThemeColor, setThemeColor} from './app/utils/util';

if (environment.production) {
  enableProdMode();
}

const color =
   environment.isMultiColorActive || environment.isDarkSwitchActive
     ? getThemeColor() : environment.defaultColor;

const themeStyleSheetElement = setThemeColor(color);
const bootstrapApp = () =>
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

themeStyleSheetElement.onload = () => bootstrapApp();
