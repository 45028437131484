/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountingAccountType } from './accountingAccountType';
import { AccountingLedgerType } from './accountingLedgerType';


export interface AccountingAccount { 
    id?: string;
    /**
     * The grouping identifier for accounts e.g. AIRBNB.COM and it can be used for AIRBNB.COM_AR and AIRBNB.COM_AP
     */
    account_identifier?: string;
    account_code?: string;
    ledger_type?: AccountingLedgerType;
    account_type?: AccountingAccountType;
    system?: boolean;
    creation_timestamp?: string;
    active?: boolean;
}

