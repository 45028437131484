/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InventoryBudgetAnalysisFilterAllOf } from './inventoryBudgetAnalysisFilterAllOf';
import { NumberRange } from './numberRange';
import { RevenueManagementFilter } from './revenueManagementFilter';
import { BudgetAnalysisSortField } from './budgetAnalysisSortField';
import { SortOrder } from './sortOrder';
import { BudgetAnalysisType } from './budgetAnalysisType';
import { PropertyState } from './propertyState';


export interface InventoryBudgetAnalysisFilter { 
    /**
     * The start date to retrieve the statistics for
     */
    date_from: string;
    /**
     * The end date to retrieve the statistics for
     */
    date_to: string;
    contract_ids?: Array<string>;
    landowner_ids?: Array<string>;
    building_ids?: Array<string>;
    locations?: Array<string>;
    property_ids?: Array<string>;
    property_states?: Array<PropertyState>;
    number_of_bedrooms_range?: NumberRange;
    number_of_guests_range?: NumberRange;
    type: BudgetAnalysisType;
    page_number?: number;
    page_size?: number;
    sort_field?: BudgetAnalysisSortField;
    sort_order?: SortOrder;
}

