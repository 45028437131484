<div class="my-4 form-group" [class.has-float-label]="to.floatLabel">
  <div class="d-flex flex-row align-items-start">
    <img [src]="formControl.value" appSafeImage class="image-preview"/>
    <div class="ml-4 flex-grow-1">
      <label>{{this.to.label}}</label>
      <input class="form-control mr-2" [formControl]="formControl"
             [minLength]="to.minLength"
             [maxLength]="to.maxLength"
             [required]="to.required">
    </div>
  </div>
</div>
