<div *ngIf="!!reservation && !!property">
  <div class="stay-card">
    <div class="card card-thumb-list d-flex flex-row mb-3">
      <div>
        <img appSafeImage class="list-thumbnail responsive border-0 card-thumb-list-thumb card-img-left"
             [src]="property.channel_manager_details.image_url" alt="property image">
      </div>
      <div class="p-3 flex-grow-1 d-flex flex-row justify-content-center">
        <div>
          <p class="text-muted">Reservation Summary</p>
          <div>
            <strong>
              <span [tooltip]="property.channel_manager_details.name">{{property.friendly_reference}}</span>
              <span *ngIf="reservation.rooms[0]?.name">&nbsp;-&nbsp;{{reservation.rooms[0]?.name}}</span>
            </strong>
          </div>
          <p class="m-0 pb-1 text-small">
            {{reservation.check_in_date | dmy_date}} - {{reservation.check_out_date | dmy_date}}
            <i class="fa fa-calendar-check"></i>
          </p>
        </div>
      </div>
      <div class="p-3 flex-grow-1 d-flex flex-row justify-content-center border-left border-light">
        <div>
          <p class="text-muted">Guest details</p>
          <div>
            <strong>{{reservation.guest_details.name}}&nbsp;{{reservation.guest_details.surname}}</strong>
          </div>
          <span>{{reservation.guest_details.phone}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
