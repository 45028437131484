/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PinCodeType } from './pinCodeType';


export interface PinCodeDetails { 
    pin_code_id?: string;
    pin_code_alias?: string;
    lock_id?: string;
    pin_code?: string;
    pin_code_type?: PinCodeType;
    start_time?: string;
    end_time?: string;
}

