/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CommissionContractDetails } from './commissionContractDetails';
import { ContractType } from './contractType';
import { ServiceManagementContractDetails } from './serviceManagementContractDetails';
import { ArbitrageContractDetails } from './arbitrageContractDetails';


export interface ContractRegisterParams { 
    owner_id: string;
    property_ids?: Array<string>;
    friendly_reference: string;
    contract_type: ContractType;
    manual_rating?: number;
    start_date: string;
    end_date: string;
    auto_renewal?: boolean;
    termination_notification_months?: number;
    agent_fees_applicable?: boolean;
    use_self_invoicing?: boolean;
    invoicing_header?: string;
    custom_notes?: string;
    arbitrage_details?: ArbitrageContractDetails;
    commission_details?: CommissionContractDetails;
    service_management_details?: ServiceManagementContractDetails;
}

