/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerReferenceType } from './customerReferenceType';
import { EmailState } from './emailState';


export interface EmailsFilter { 
    /**
     * The page number of the result (the first page is 0)
     */
    page_number?: number;
    /**
     * The number of items returned in each page
     */
    page_size?: number;
    incoming?: boolean;
    state?: EmailState;
    created_date_from?: string;
    created_date_to?: string;
    customer_reference_type?: CustomerReferenceType;
    customer_reference?: string;
    sender_email?: string;
    recipient_email?: string;
    request_id?: string;
    communication_type?: string;
}

