/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type StatementEntryType = 'OWNER_CONTRIBUTION' | 'PAYMENT' | 'INCOMING_PAYMENT' | 'OTHER';

export const StatementEntryType = {
    OWNERCONTRIBUTION: 'OWNER_CONTRIBUTION' as StatementEntryType,
    PAYMENT: 'PAYMENT' as StatementEntryType,
    INCOMINGPAYMENT: 'INCOMING_PAYMENT' as StatementEntryType,
    OTHER: 'OTHER' as StatementEntryType
};

