import {Component} from '@angular/core';
import {ValueComponent} from '@forms/primitives/value/value.component';

@Component({
  selector: 'formly-labelled-link',
  template: '<div>{{label}}</div>' +
    '<div>' +
    '<a *ngIf="showAsLink()" href="" (click)="getOnClick($event);"> {{ value }} </a>' +
    '<div *ngIf="!showAsLink()"> {{ defaultText }} </div>' +
    '</div>',
})
export class LabelledLinkValueComponent extends ValueComponent {
  public showAsLink() {
    return !!this.value;
  }

  get label() {
    return this.to.label;
  }

  get defaultText() {
    return this.to.defaultText;
  }

  getOnClick(event: Event) {
    if (this.to.onClick) {
      return this.to.onClick(event, this.model);
    } else {
      return () => true;
    }
  }
}
