/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A commercial document
 */
export interface CommercialDocument { 
    /**
     * The document name
     */
    document_name?: string;
    /**
     * The document number
     */
    document_number?: string;
    /**
     * The document type
     */
    document_type?: string;
}

