/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractState } from './contractState';
import { CommissionContractDetails } from './commissionContractDetails';
import { LandOwnerShortRef } from './landOwnerShortRef';
import { ContractType } from './contractType';
import { PropertyShortRef } from './propertyShortRef';
import { ServiceManagementContractDetails } from './serviceManagementContractDetails';
import { ArbitrageContractDetails } from './arbitrageContractDetails';


export interface Contract { 
    id?: string;
    friendly_reference?: string;
    contract_type?: ContractType;
    state?: ContractState;
    owner_short_ref?: LandOwnerShortRef;
    manual_rating?: number;
    property_ids?: Array<string>;
    property_refs?: Array<PropertyShortRef>;
    start_date?: string;
    end_date?: string;
    auto_renewal?: boolean;
    termination_notification_months?: number;
    agent_fees_applicable?: boolean;
    use_self_invoicing?: boolean;
    invoicing_header?: string;
    custom_notes?: string;
    created_timestamp?: string;
    last_updated?: string;
    arbitrage_details?: ArbitrageContractDetails;
    commission_details?: CommissionContractDetails;
    service_management_details?: ServiceManagementContractDetails;
    document_name?: string;
}

