/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PropertyImage } from './propertyImage';
import { Coordinates } from './coordinates';
import { PropertyType } from './propertyType';


export interface PropertyChannelManagerDetails { 
    name?: string;
    type?: PropertyType;
    street_address?: string;
    location?: string;
    post_code?: string;
    description?: string;
    coordinates?: Coordinates;
    license_number?: string;
    /**
     * The space available for guests at this property in square meters
     */
    space_sqm?: number;
    number_of_bedrooms?: number;
    number_of_bathrooms?: number;
    standard_number_of_guests?: number;
    maximum_number_of_guests?: number;
    cleaning_price?: number;
    /**
     * The main image of the property as saved in rentals united
     */
    image_url?: string;
    /**
     * List of property image URLs and the relevant captions
     */
    images?: Array<PropertyImage>;
    /**
     * The number of available units that can be booked in this property
     */
    number_of_units?: number;
    /**
     * The channel manager id of the building
     */
    building_id?: string;
    building_name?: string;
}

