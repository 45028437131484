import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {AuthService} from '@app/shared/auth.service';
import {RedirectService} from '@app/shared/redirect.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private redirectService: RedirectService,
    private router: Router
  ) {}

  authCheck$ = this.authService.isLoggedIn().pipe(
    tap((isLoggedIn: boolean) => this.handleLoggedOutRedirect(isLoggedIn))
  );

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.checkAuthentication();
  }

  handleLoggedOutRedirect(isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.authService.signOut();
      this.redirectService.setRedirectUrl(this.router.url);
      this.router.navigateByUrl('user/login');
    }
  }

  checkAuthentication() {
    return this.authCheck$;
  }
}
