import {Component} from '@angular/core';
import {ValueComponent} from '@forms/primitives/value/value.component';

@Component({
  selector: 'formly-mapped-value',
  template: '<div>  {{ format(value) }} </div>',
})
export class MappedValueComponent extends ValueComponent {
  format(v) {
    return this.to.map ? this.to.map[v] : v;
  }
}
