/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RentalEntityType } from './rentalEntityType';
import { ReservationGuestDetails } from './reservationGuestDetails';


export interface LongLetReservationParams { 
    property_id: string;
    sales_channel_id?: string;
    check_in_date: string;
    check_out_date: string;
    rent_per_month_including_vat: number;
    agency_fee_including_vat: number;
    deposit: number;
    rental_entity_type: RentalEntityType;
    guest_details: ReservationGuestDetails;
    comments?: string;
}

