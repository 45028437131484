/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NumberRange } from './numberRange';
import { PropertyType } from './propertyType';
import { PropertyUnitType } from './propertyUnitType';
import { PropertyState } from './propertyState';


export interface PropertyFilter { 
    /**
     * The page number of the result (the first page is 0)
     */
    page_number?: number;
    /**
     * The number of items returned in each page
     */
    page_size?: number;
    /**
     * The friendly reference of the property
     */
    partial_friendly_reference?: string;
    /**
     * The name of the property as listed on the channel manager
     */
    partial_name?: string;
    /**
     * The external ID of the property (e.g. from rentals united)
     */
    external_id?: string;
    property_type?: PropertyType;
    property_types?: Array<PropertyType>;
    groupings?: Array<string>;
    location?: string;
    locations?: Array<string>;
    state?: PropertyState;
    states?: Array<PropertyState>;
    owner_id?: string;
    /**
     * The friendly reference of the property
     */
    partial_owner_friendly_reference?: string;
    property_details_provided?: boolean;
    linking_done?: boolean;
    registration_complete?: boolean;
    building_id?: string;
    partial_building_name?: string;
    property_unit_type?: PropertyUnitType;
    contract_id?: string;
    property_ids?: Array<string>;
    number_of_bedrooms_range?: NumberRange;
    number_of_guests_range?: NumberRange;
    /**
     * List of IDs referencing property tags to filter by
     */
    tags?: Array<string>;
}

