/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PropertyAmenityType = 'KITCHEN' | 'BATHROOM' | 'LIVING' | 'OUTDOOR' | 'GENERAL' | 'CLEANING_LAUNDRY' | 'SAFETY' | 'ACCESSIBILITY' | 'REMOTE_WORKING' | 'ENTERTAINMENT' | 'HIGHLIGHTS';

export const PropertyAmenityType = {
    KITCHEN: 'KITCHEN' as PropertyAmenityType,
    BATHROOM: 'BATHROOM' as PropertyAmenityType,
    LIVING: 'LIVING' as PropertyAmenityType,
    OUTDOOR: 'OUTDOOR' as PropertyAmenityType,
    GENERAL: 'GENERAL' as PropertyAmenityType,
    CLEANINGLAUNDRY: 'CLEANING_LAUNDRY' as PropertyAmenityType,
    SAFETY: 'SAFETY' as PropertyAmenityType,
    ACCESSIBILITY: 'ACCESSIBILITY' as PropertyAmenityType,
    REMOTEWORKING: 'REMOTE_WORKING' as PropertyAmenityType,
    ENTERTAINMENT: 'ENTERTAINMENT' as PropertyAmenityType,
    HIGHLIGHTS: 'HIGHLIGHTS' as PropertyAmenityType
};

