import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeadroomModule} from '@ctrl/ngx-headroom';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ComponentsCarouselModule} from 'src/app/components/carousel/components.carousel.module';
import {SharedModule} from '../shared/shared.module';
import {ViewRoutingModule} from './views.routing';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ViewRoutingModule,
    SharedModule,
    ComponentsCarouselModule,
    TabsModule.forRoot(),
    BrowserAnimationsModule,
    HeadroomModule,
    ScrollToModule.forRoot()
  ]
})
export class ViewsModule {
}
