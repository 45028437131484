/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type GuestServiceBookingStatus = 'PENDING' | 'BOOKED' | 'PAID' | 'CANCELLED' | 'EXPIRED' | 'REQUIRES_PAYMENT_CANCELLATION' | 'REQUIRES_PAYMENT_REFUND';

export const GuestServiceBookingStatus = {
    PENDING: 'PENDING' as GuestServiceBookingStatus,
    BOOKED: 'BOOKED' as GuestServiceBookingStatus,
    PAID: 'PAID' as GuestServiceBookingStatus,
    CANCELLED: 'CANCELLED' as GuestServiceBookingStatus,
    EXPIRED: 'EXPIRED' as GuestServiceBookingStatus,
    REQUIRESPAYMENTCANCELLATION: 'REQUIRES_PAYMENT_CANCELLATION' as GuestServiceBookingStatus,
    REQUIRESPAYMENTREFUND: 'REQUIRES_PAYMENT_REFUND' as GuestServiceBookingStatus
};

