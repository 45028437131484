/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RoomAllocationState = 'AUTOMATICALLY_ALLOCATED' | 'MANUALLY_ALLOCATED' | 'NOT_ALLOCATED' | 'CHANNEL_MANAGER_ALLOCATED';

export const RoomAllocationState = {
    AUTOMATICALLYALLOCATED: 'AUTOMATICALLY_ALLOCATED' as RoomAllocationState,
    MANUALLYALLOCATED: 'MANUALLY_ALLOCATED' as RoomAllocationState,
    NOTALLOCATED: 'NOT_ALLOCATED' as RoomAllocationState,
    CHANNELMANAGERALLOCATED: 'CHANNEL_MANAGER_ALLOCATED' as RoomAllocationState
};

