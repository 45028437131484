import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@app/shared/auth.service';
import {SessionQuery} from '@app/shared/auth/session/session.query';
import {RedirectService} from '@app/shared/redirect.service';
import {environment} from '@env/environment';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private query: SessionQuery,
    private redirectService: RedirectService,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.query.getToken();
    const apiBaseUrl = environment.apiUrl;
    if (authToken && req.url.startsWith(apiBaseUrl)) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + authToken,
          Time_Zone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      });
    }
    return next.handle(req).pipe(catchError(err => this.handleError(err)));

  }

  handleError( err: any) {
    if ( err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 401: this.handleUnauthorized(); return throwError({message: 'Session Expired. Please login again.'});
        default: break;
      }
    }

    return throwError(err);
  }

  /**
   * Cleans token from state and redirects to login page
   */
  handleUnauthorized() {
    this.authService.signOut();
    this.redirectService.setRedirectUrl(this.router.url);
    this.router.navigate(['user/login']);
  }
}
