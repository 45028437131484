/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PropertyOrLocation } from './propertyOrLocation';


export interface InventoryBudgetAnalysis { 
    property_or_location?: PropertyOrLocation;
    /**
     * the aggregate rental income budget of an analysed property/location
     */
    rental_income_budget?: number;
    /**
     * the aggregate occupancy budget of an analysed property/location
     */
    occupancy_budget?: number;
    /**
     * the aggregate value of the actual rental income of an analysed property/location
     */
    rental_income_actual?: number;
    /**
     * the aggregate value of the actual occupancy of an analysed property/location
     */
    occupancy_actual?: number;
}

