<ng-container>
  <div class="form-group" [class.has-float-label]="to.floatLabel">
    <label>
      <span>{{ to.label }} <ng-container *ngIf="to.required">*</ng-container></span>
      <a *ngIf="to.allowResetting && hasValue()" class="fa fa-times fa-1x pl-2"
         (click)="emptyValue()"></a>
    </label>

    <ng-container *ngIf="!to.dateRangePicker; else dateRangePicker">
      <input class="form-control"
             (bsValueChange)="onValueChange($event)"
             [disabled]="to.disabled"
             container="html"
             [readOnly]="to.readonly"
             bsDatepicker
             [placeholder]="to.placeholder"
             [autocomplete]="to.autocomplete"
             [bsConfig]="{adaptivePosition: true, dateInputFormat : 'DD-MM-YYYY', showWeekNumbers: false}"
             [minDate]="minDate"
             [maxDate]="maxDate"
             [bsValue]="getDate()"
             [value]="formControl.value | dmy_date"
             #picker="bsDatepicker">
    </ng-container>
    <ng-template #dateRangePicker>
      <input class="form-control"
             bsDaterangepicker
             container="html"
             readonly
             [(ngModel)]="dateRangeValue"
             [autocomplete]="to.autocomplete"
             (bsValueChange)="onDateRangeValueChange($event)"
             [disabled]="to.disabled"
             [maxDate]="minDate"
             [minDate]="maxDate"
             [bsConfig]="{adaptivePosition: true, displayMonths : 1, rangeInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}">
    </ng-template>
  </div>
</ng-container>
