import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountingService } from './api/accounting.service';
import { AccountingProviderService } from './api/accountingProvider.service';
import { AdminGuestServicesService } from './api/adminGuestServices.service';
import { AdminUsersService } from './api/adminUsers.service';
import { AuditLogsService } from './api/auditLogs.service';
import { AuthService } from './api/auth.service';
import { BuildingsInventoryService } from './api/buildingsInventory.service';
import { CmGuestyService } from './api/cmGuesty.service';
import { CmRentalsUnitedService } from './api/cmRentalsUnited.service';
import { CommercialDocumentsService } from './api/commercialDocuments.service';
import { CommonFinancialsService } from './api/commonFinancials.service';
import { CommunicationEmailsService } from './api/communicationEmails.service';
import { ContractsInventoryService } from './api/contractsInventory.service';
import { ContractsStatementService } from './api/contractsStatement.service';
import { CustomersService } from './api/customers.service';
import { InventoryCostsService } from './api/inventoryCosts.service';
import { InventoryDocumentsService } from './api/inventoryDocuments.service';
import { LandOwnersInventoryService } from './api/landOwnersInventory.service';
import { OperationsMonitoringService } from './api/operationsMonitoring.service';
import { PerformanceDashboardService } from './api/performanceDashboard.service';
import { PinCodesService } from './api/pinCodes.service';
import { PropertyInventoryService } from './api/propertyInventory.service';
import { ReservationsService } from './api/reservations.service';
import { ReservationsSalesChannelsService } from './api/reservationsSalesChannels.service';
import { RevenueManagementDashboardService } from './api/revenueManagementDashboard.service';
import { UtilitiesService } from './api/utilities.service';
import { UtilitiesAccountingService } from './api/utilitiesAccounting.service';
import { UtilitiesPinCodesService } from './api/utilitiesPinCodes.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
