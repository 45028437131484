/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerEmailAddress } from './customerEmailAddress';
import { CustomerState } from './customerState';


export interface Customer { 
    id?: string;
    /**
     * System generated customer friendly reference
     */
    friendly_reference?: string;
    state?: CustomerState;
    name?: string;
    surname?: string;
    email?: string;
    emails?: Array<CustomerEmailAddress>;
    phone?: string;
    skype?: string;
    passport_no?: string;
    address?: string;
    zipcode?: string;
    country?: string;
    vat_number?: string;
    additional_info?: string;
    preferred_language?: string;
    last_updated?: string;
    registration_timestamp?: string;
}

