/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PersonTitle = 'MR' | 'MS' | 'MRS' | 'MX' | 'PROF' | 'ING' | 'DR';

export const PersonTitle = {
    MR: 'MR' as PersonTitle,
    MS: 'MS' as PersonTitle,
    MRS: 'MRS' as PersonTitle,
    MX: 'MX' as PersonTitle,
    PROF: 'PROF' as PersonTitle,
    ING: 'ING' as PersonTitle,
    DR: 'DR' as PersonTitle
};

