import {Pipe, PipeTransform} from '@angular/core';

/**
 * A pipe to be used to show a value as string if truthy else show 'N/A'
 */
@Pipe({
  name: 'display_optional'
})
export class DisplayOptionalPipe implements PipeTransform {

  transform(value: string): string {
    return value ? value : 'N/A';
  }

}
