export * from './accounting.service';
import { AccountingService } from './accounting.service';
export * from './accountingProvider.service';
import { AccountingProviderService } from './accountingProvider.service';
export * from './adminGuestServices.service';
import { AdminGuestServicesService } from './adminGuestServices.service';
export * from './adminUsers.service';
import { AdminUsersService } from './adminUsers.service';
export * from './auditLogs.service';
import { AuditLogsService } from './auditLogs.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './buildingsInventory.service';
import { BuildingsInventoryService } from './buildingsInventory.service';
export * from './cmGuesty.service';
import { CmGuestyService } from './cmGuesty.service';
export * from './cmRentalsUnited.service';
import { CmRentalsUnitedService } from './cmRentalsUnited.service';
export * from './commercialDocuments.service';
import { CommercialDocumentsService } from './commercialDocuments.service';
export * from './commonFinancials.service';
import { CommonFinancialsService } from './commonFinancials.service';
export * from './communicationEmails.service';
import { CommunicationEmailsService } from './communicationEmails.service';
export * from './contractsInventory.service';
import { ContractsInventoryService } from './contractsInventory.service';
export * from './contractsStatement.service';
import { ContractsStatementService } from './contractsStatement.service';
export * from './customers.service';
import { CustomersService } from './customers.service';
export * from './inventoryCosts.service';
import { InventoryCostsService } from './inventoryCosts.service';
export * from './inventoryDocuments.service';
import { InventoryDocumentsService } from './inventoryDocuments.service';
export * from './landOwnersInventory.service';
import { LandOwnersInventoryService } from './landOwnersInventory.service';
export * from './operationsMonitoring.service';
import { OperationsMonitoringService } from './operationsMonitoring.service';
export * from './performanceDashboard.service';
import { PerformanceDashboardService } from './performanceDashboard.service';
export * from './pinCodes.service';
import { PinCodesService } from './pinCodes.service';
export * from './propertyInventory.service';
import { PropertyInventoryService } from './propertyInventory.service';
export * from './reservations.service';
import { ReservationsService } from './reservations.service';
export * from './reservationsSalesChannels.service';
import { ReservationsSalesChannelsService } from './reservationsSalesChannels.service';
export * from './revenueManagementDashboard.service';
import { RevenueManagementDashboardService } from './revenueManagementDashboard.service';
export * from './utilities.service';
import { UtilitiesService } from './utilities.service';
export * from './utilitiesAccounting.service';
import { UtilitiesAccountingService } from './utilitiesAccounting.service';
export * from './utilitiesPinCodes.service';
import { UtilitiesPinCodesService } from './utilitiesPinCodes.service';
export const APIS = [AccountingService, AccountingProviderService, AdminGuestServicesService, AdminUsersService, AuditLogsService, AuthService, BuildingsInventoryService, CmGuestyService, CmRentalsUnitedService, CommercialDocumentsService, CommonFinancialsService, CommunicationEmailsService, ContractsInventoryService, ContractsStatementService, CustomersService, InventoryCostsService, InventoryDocumentsService, LandOwnersInventoryService, OperationsMonitoringService, PerformanceDashboardService, PinCodesService, PropertyInventoryService, ReservationsService, ReservationsSalesChannelsService, RevenueManagementDashboardService, UtilitiesService, UtilitiesAccountingService, UtilitiesPinCodesService];
