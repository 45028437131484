import {Component} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-addons',
  styleUrls: ['./clickable-wrapper.component.scss'],
  template: '<div class="clickable"><ng-container #fieldComponent></ng-container></div>',
})
export class ClickableWrapperComponent extends FieldWrapper {

}
