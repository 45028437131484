/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LandOwnerType } from './landOwnerType';


export interface LandOwnersFilter { 
    /**
     * The page number of the result (the first page is 0)
     */
    page_number?: number;
    /**
     * The number of items returned in each page
     */
    page_size?: number;
    /**
     * The friendly reference of the owner
     */
    partial_friendly_reference?: string;
    /**
     * The friendly name of the owner
     */
    friendly_name?: string;
    land_owner_type?: LandOwnerType;
    owner_id?: string;
    /**
     * Any sub string that appears in the name of any land owner
     */
    partial_name?: string;
}

