import {CurrencyPipe, DatePipe, TitleCasePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {DisplayOptionalPipe} from '@app/pipes/display-optional.pipe';
import {Dmy_datePipe} from '@app/pipes/DmyDate.pipe';
import {Dmy_datetimePipe} from '@app/pipes/DmyDateTime.pipe';
import {EnumPipe} from '@app/pipes/enum.pipe';

@NgModule({
  declarations: [EnumPipe, DisplayOptionalPipe, Dmy_datePipe, Dmy_datetimePipe],
  imports: [],
  exports: [
    EnumPipe,
    DisplayOptionalPipe,
    Dmy_datePipe,
    Dmy_datetimePipe
  ],
  providers: [
    EnumPipe,
    DatePipe,
    CurrencyPipe,
    TitleCasePipe,
    DisplayOptionalPipe,
    Dmy_datePipe,
    Dmy_datetimePipe
  ]
})
export class PipesModule {
}
