import {Injectable} from '@angular/core';
import {QueryEntity, toBoolean} from '@datorama/akita';
import {SessionState, SessionStore} from './session.store';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends QueryEntity<SessionState> {
  isLoggedIn$ = this.select(state => toBoolean(state.access_token));
  user$ =  this.select(state => state.user);

  constructor(protected store: SessionStore) {
    super(store);
  }

  public getToken(): string {
    const { access_token } = this.getValue();
    return access_token;
  }

  public getUserId(): string {
    const { user } = this.getValue();
    return user?.id;
  }

}
