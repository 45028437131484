/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeeEntry } from './feeEntry';


export interface ReservationSummaryCostEntries { 
    otafee_rent?: FeeEntry;
    otafee_cleaning?: FeeEntry;
    otafee_touristtax?: FeeEntry;
    otafee_cancellation?: FeeEntry;
    paymentfee?: FeeEntry;
    touristtax_due?: FeeEntry;
}

