/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReservationPinStatus = 'MISSING' | 'READY' | 'PIN_ERROR';

export const ReservationPinStatus = {
    MISSING: 'MISSING' as ReservationPinStatus,
    READY: 'READY' as ReservationPinStatus,
    PINERROR: 'PIN_ERROR' as ReservationPinStatus
};

