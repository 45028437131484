/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoomState } from './roomState';


export interface Room { 
    id?: string;
    name?: string;
    lock_id?: string;
    state?: RoomState;
    property_id?: string;
}

