import {Component} from '@angular/core';
import {ValueComponent} from '@forms/primitives/value/value.component';

@Component({
  selector: 'formly-icon-value',
  template: '<div class="h-100 d-flex justify-content-center align-items-center"><i class="fa " [className]="value"></i></div>',
})
export class IconValueComponent extends ValueComponent {

}
