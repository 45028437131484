import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {get} from 'lodash';

@Component({
  selector: 'app-formly-tick',
  templateUrl: './tick.component.html',
})
export class TickComponent extends FieldType {
  constructor() {
    super();
  }

  get value() {
    return get(this.model, this.key);
  }

}
