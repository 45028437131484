import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent implements OnInit {

  list: any[];
  confirmAction: () => void;

  constructor(private confirmationModal: BsModalRef) {
  }

  ngOnInit(): void {
    this.confirmAction = this.list[0];
  }

  confirm() {
    this.confirmAction();
    this.confirmationModal.hide();
  }

  cancel() {
    this.confirmationModal.hide();
  }

}
