import {Injectable} from '@angular/core';
import {AdminUsersService} from '@api/api/adminUsers.service';
import {AdminChangePasswordParams} from '@api/model/adminChangePasswordParams';
import {SessionQuery} from './session.query';

@Injectable({providedIn: 'root'})
export class SessionService {

  constructor(
    private auservice: AdminUsersService,
    private query: SessionQuery) {
  }

  changePassword(data: AdminChangePasswordParams) {
    const {old_password, new_password} = data;
    const id = this.query.getUserId();
    return this.auservice.changePasswordForUser(id, {old_password, new_password});
  }
}
