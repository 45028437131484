<ng-select class="formly-select"
  [items]="options$ | async"
  [ngStyle]="to.style"
  [appendTo]="'body'"
  [placeholder]="to.placeholder || to.label"
  [typeahead]="typeaheadEmitter$"
  [formControl]="formControl"
  [multiple]="to.multiple"
  [bindValue]="to.valueProp"
  [bindLabel]="to.labelProp"
  [hideSelected]="to.hideSelected"
  [compareWith]="compareWith()"
  (scrollToEnd)="onScrollToEnd()">
  <ng-template ng-option-tmp let-item="item">
    <span [title]="getLabel(item)">{{ getLabel(item) }}</span>
  </ng-template>
</ng-select>
