/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PropertyAmenity } from './propertyAmenity';
import { ContractShortRef } from './contractShortRef';
import { PropertyTag } from './propertyTag';
import { PropertyChannelManagerDetails } from './propertyChannelManagerDetails';
import { PropertyBudget } from './propertyBudget';
import { BasicLandOwnerShortRef } from './basicLandOwnerShortRef';
import { PropertyBudgetAnalysis } from './propertyBudgetAnalysis';
import { CheckInInfo } from './checkInInfo';
import { PropertyAdditionalDetails } from './propertyAdditionalDetails';
import { PropertyUnitType } from './propertyUnitType';
import { Room } from './room';
import { PropertyState } from './propertyState';


export interface Property { 
    id?: string;
    external_id?: string;
    provider_key?: string;
    state?: PropertyState;
    friendly_reference?: string;
    owner_short_ref?: BasicLandOwnerShortRef;
    active_contract_short_ref?: ContractShortRef;
    contracts_ids?: Array<ContractShortRef>;
    created_timestamp?: string;
    last_updated?: string;
    channel_manager_details?: PropertyChannelManagerDetails;
    additional_details?: PropertyAdditionalDetails;
    check_in_info?: CheckInInfo;
    target_yield?: Array<number>;
    grouping?: string;
    /**
     * A friendly reference provided by the landowners to identify the property for themselves
     */
    owner_property_reference?: string;
    /**
     * Boolean whether all required basic information about the property has been provided
     */
    property_details_provided?: boolean;
    property_unit_type?: PropertyUnitType;
    building_id?: string;
    rooms?: Array<Room>;
    budgets?: Array<PropertyBudget>;
    budget_analysis?: Array<PropertyBudgetAnalysis>;
    amenities?: Array<PropertyAmenity>;
    tags?: Array<PropertyTag>;
}

