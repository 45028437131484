/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AccountMapping } from '../model/models';
import { TaxMapping } from '../model/models';
import { UpdateAccountMappingParams } from '../model/models';
import { UpdateTaxMappingParams } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class AccountingProviderService {

    protected basePath = 'https://dev.zzzing.com/api/admin';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Delete account mapping by system accounting account code
     * Delete account mapping by system accounting account code
     * @param accountingProvider The accounting provider (e.g. SAGE)
     * @param accountCode System accounting account code to delete mapping
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAccountMapping(accountingProvider: string, accountCode: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AccountMapping>>;
    public deleteAccountMapping(accountingProvider: string, accountCode: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AccountMapping>>>;
    public deleteAccountMapping(accountingProvider: string, accountCode: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AccountMapping>>>;
    public deleteAccountMapping(accountingProvider: string, accountCode: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountingProvider === null || accountingProvider === undefined) {
            throw new Error('Required parameter accountingProvider was null or undefined when calling deleteAccountMapping.');
        }
        if (accountCode === null || accountCode === undefined) {
            throw new Error('Required parameter accountCode was null or undefined when calling deleteAccountMapping.');
        }

        let headers = this.defaultHeaders;

        // authentication (zzzing_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<Array<AccountMapping>>(`${this.configuration.basePath}/integrations/accounting/${encodeURIComponent(String(accountingProvider))}/config/accounts/${encodeURIComponent(String(accountCode))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tax mapping by system tax category
     * Delete tax mapping by system tax category
     * @param accountingProvider The accounting provider (e.g. SAGE)
     * @param taxCategory System tax category for which to create / update mapping
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTaxMapping(accountingProvider: string, taxCategory: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<TaxMapping>>;
    public deleteTaxMapping(accountingProvider: string, taxCategory: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<TaxMapping>>>;
    public deleteTaxMapping(accountingProvider: string, taxCategory: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<TaxMapping>>>;
    public deleteTaxMapping(accountingProvider: string, taxCategory: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountingProvider === null || accountingProvider === undefined) {
            throw new Error('Required parameter accountingProvider was null or undefined when calling deleteTaxMapping.');
        }
        if (taxCategory === null || taxCategory === undefined) {
            throw new Error('Required parameter taxCategory was null or undefined when calling deleteTaxMapping.');
        }

        let headers = this.defaultHeaders;

        // authentication (zzzing_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<Array<TaxMapping>>(`${this.configuration.basePath}/integrations/accounting/${encodeURIComponent(String(accountingProvider))}/config/tax/${encodeURIComponent(String(taxCategory))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountingProvider The accounting provider (e.g. SAGE)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountMappings(accountingProvider: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AccountMapping>>;
    public getAccountMappings(accountingProvider: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AccountMapping>>>;
    public getAccountMappings(accountingProvider: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AccountMapping>>>;
    public getAccountMappings(accountingProvider: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountingProvider === null || accountingProvider === undefined) {
            throw new Error('Required parameter accountingProvider was null or undefined when calling getAccountMappings.');
        }

        let headers = this.defaultHeaders;

        // authentication (zzzing_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AccountMapping>>(`${this.configuration.basePath}/integrations/accounting/${encodeURIComponent(String(accountingProvider))}/config/accounts`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountingProvider The accounting provider (e.g. SAGE)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTaxMappings(accountingProvider: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<TaxMapping>>;
    public getTaxMappings(accountingProvider: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<TaxMapping>>>;
    public getTaxMappings(accountingProvider: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<TaxMapping>>>;
    public getTaxMappings(accountingProvider: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountingProvider === null || accountingProvider === undefined) {
            throw new Error('Required parameter accountingProvider was null or undefined when calling getTaxMappings.');
        }

        let headers = this.defaultHeaders;

        // authentication (zzzing_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<TaxMapping>>(`${this.configuration.basePath}/integrations/accounting/${encodeURIComponent(String(accountingProvider))}/config/tax`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create/Update account mapping by system account code
     * Create/Update account mapping by system account code
     * @param accountingProvider The accounting provider (e.g. SAGE)
     * @param accountCode System accounting account code to create / update mapping
     * @param updateAccountMappingParams Account fields to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putAccountMapping(accountingProvider: string, accountCode: string, updateAccountMappingParams: UpdateAccountMappingParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AccountMapping>;
    public putAccountMapping(accountingProvider: string, accountCode: string, updateAccountMappingParams: UpdateAccountMappingParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AccountMapping>>;
    public putAccountMapping(accountingProvider: string, accountCode: string, updateAccountMappingParams: UpdateAccountMappingParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AccountMapping>>;
    public putAccountMapping(accountingProvider: string, accountCode: string, updateAccountMappingParams: UpdateAccountMappingParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountingProvider === null || accountingProvider === undefined) {
            throw new Error('Required parameter accountingProvider was null or undefined when calling putAccountMapping.');
        }
        if (accountCode === null || accountCode === undefined) {
            throw new Error('Required parameter accountCode was null or undefined when calling putAccountMapping.');
        }
        if (updateAccountMappingParams === null || updateAccountMappingParams === undefined) {
            throw new Error('Required parameter updateAccountMappingParams was null or undefined when calling putAccountMapping.');
        }

        let headers = this.defaultHeaders;

        // authentication (zzzing_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<AccountMapping>(`${this.configuration.basePath}/integrations/accounting/${encodeURIComponent(String(accountingProvider))}/config/accounts/${encodeURIComponent(String(accountCode))}`,
            updateAccountMappingParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create/Update tax mapping by tax category
     * Create/Update tax mapping by system account code
     * @param accountingProvider The accounting provider (e.g. SAGE)
     * @param taxCategory System tax category for which to create / update mapping
     * @param updateTaxMappingParams Tax mapping fields to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putTaxMapping(accountingProvider: string, taxCategory: string, updateTaxMappingParams: UpdateTaxMappingParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TaxMapping>;
    public putTaxMapping(accountingProvider: string, taxCategory: string, updateTaxMappingParams: UpdateTaxMappingParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TaxMapping>>;
    public putTaxMapping(accountingProvider: string, taxCategory: string, updateTaxMappingParams: UpdateTaxMappingParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TaxMapping>>;
    public putTaxMapping(accountingProvider: string, taxCategory: string, updateTaxMappingParams: UpdateTaxMappingParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountingProvider === null || accountingProvider === undefined) {
            throw new Error('Required parameter accountingProvider was null or undefined when calling putTaxMapping.');
        }
        if (taxCategory === null || taxCategory === undefined) {
            throw new Error('Required parameter taxCategory was null or undefined when calling putTaxMapping.');
        }
        if (updateTaxMappingParams === null || updateTaxMappingParams === undefined) {
            throw new Error('Required parameter updateTaxMappingParams was null or undefined when calling putTaxMapping.');
        }

        let headers = this.defaultHeaders;

        // authentication (zzzing_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<TaxMapping>(`${this.configuration.basePath}/integrations/accounting/${encodeURIComponent(String(accountingProvider))}/config/tax/${encodeURIComponent(String(taxCategory))}`,
            updateTaxMappingParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
