/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReservationPinType } from './reservationPinType';
import { ReservationPinStatus } from './reservationPinStatus';


export interface ReservationPinDetails { 
    pin_status?: ReservationPinStatus;
    property_entrance_pin_type?: ReservationPinType;
    property_entrance_active_pin?: string;
    property_entrance_communicated_pin?: string;
    property_entrance_pin_suffix?: string;
    common_entrance_pin_type?: ReservationPinType;
    common_entrance_active_pin?: string;
    common_entrance_communicated_pin?: string;
    common_entrance_pin_suffix?: string;
}

