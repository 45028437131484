import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { get } from 'lodash';

@Component({
  selector: 'app-value-styler',
  templateUrl: './style-wrapper.component.html',
})
export class StyleWrapperComponent extends FieldWrapper {

  get value() {
    return get(this.model, this.key);
  }

}
