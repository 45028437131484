/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReservationState } from './reservationState';
import { ReservationPinStatus } from './reservationPinStatus';
import { ReservationType } from './reservationType';
import { ReservationSortField } from './reservationSortField';
import { SortOrder } from './sortOrder';
import { ReservationPaymentStatus } from './reservationPaymentStatus';
import { PropertyState } from './propertyState';


export interface ReservationFilter { 
    /**
     * The page number of the result (the first page is 0)
     */
    page_number?: number;
    /**
     * The number of items returned in each page
     */
    page_size?: number;
    sort_field?: ReservationSortField;
    sort_order?: SortOrder;
    /**
     * The external ID of the reservation (e.g. from rentals united)
     */
    vague_reservation_id?: string;
    property_id?: string;
    /**
     * The friendly reference of the property
     */
    partial_property_friendly_reference?: string;
    property_ids?: Array<string>;
    property_building_id?: string;
    property_locations?: Array<string>;
    property_states?: Array<PropertyState>;
    property_groupings?: Array<string>;
    owner_id?: string;
    /**
     * The friendly reference of the land owner
     */
    partial_owner_friendly_reference?: string;
    contract_id?: string;
    /**
     * The friendly reference of the contract
     */
    partial_contract_friendly_reference?: string;
    state?: ReservationState;
    states?: Array<ReservationState>;
    pin_status?: ReservationPinStatus;
    payment_status?: ReservationPaymentStatus;
    check_in_from_date?: string;
    check_in_to_date?: string;
    check_out_from_date?: string;
    check_out_to_date?: string;
    booking_from_date?: string;
    booking_to_date?: string;
    cancellation_from_date?: string;
    cancellation_to_date?: string;
    sales_channel_ids?: Array<string>;
    customer_id?: string;
    partial_guest_name_or_surname?: string;
    type?: ReservationType;
    has_guest_issues?: boolean;
}

