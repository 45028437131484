/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReservationTrend { 
    /**
     * the date the reservations will be checking in
     */
    date?: string;
    /**
     * the bookings checking in on \"date\" that were made after yesterday
     */
    booked_yesterday?: number;
    /**
     * the bookings checking in on \"date\" that were made in the last week
     */
    booked_in_last_week?: number;
    /**
     * the bookings checking in on \"date\" that were made in the last month
     */
    booked_in_last_month?: number;
    /**
     * the bookings checking in on \"date\" that were made in the last 2 months
     */
    booked_in_last_2_months?: number;
    /**
     * the total bookings checking in on \"date\"
     */
    total_booked?: number;
}

