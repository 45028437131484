/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ZzzingServiceType } from './zzzingServiceType';
import { GuestZzzingServicesBookingsSortField } from './guestZzzingServicesBookingsSortField';
import { SortOrder } from './sortOrder';
import { GuestServiceBookingStatus } from './guestServiceBookingStatus';
import { ZzzingServiceFulfilmentState } from './zzzingServiceFulfilmentState';


export interface GuestZzzingServicesFilter { 
    /**
     * The page number of the result (the first page is 0)
     */
    page_number?: number;
    /**
     * The number of items returned in each page
     */
    page_size?: number;
    sort_field?: GuestZzzingServicesBookingsSortField;
    sort_order?: SortOrder;
    booking_id?: string;
    customer_id?: string;
    reservation_id?: string;
    confirmation_code?: string;
    service_type?: ZzzingServiceType;
    state?: GuestServiceBookingStatus;
    states?: Array<GuestServiceBookingStatus>;
    fulfilment_states?: Array<ZzzingServiceFulfilmentState>;
    creation_date_from?: string;
    creation_date_to?: string;
    service_date_from?: string;
    service_date_to?: string;
}

