import {Component} from '@angular/core';
import {ValueComponent} from '@forms/primitives/value/value.component';

@Component({
  selector: 'formly-money-value',
  template: '<div style="user-select: all">  {{ format(value) }} </div>',
})
export class MoneyValueComponent extends ValueComponent {
  format(v) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: this.to.currency ? this.to.currency : 'EUR'
    });
    return formatter.format(v);
  }
}
