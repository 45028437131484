import {Component} from '@angular/core';
import {Dmy_datetimePipe} from '@app/pipes/DmyDateTime.pipe';
import {FieldType} from '@ngx-formly/core';
import {get} from 'lodash';

@Component({
  selector: 'formly-formatted-date-time-input',
  template:  '<div class="form-group">' +
      '<label>{{label}}</label>' +
      '<div>' +
          '<input class="form-control" [value]="format(value)" [disabled]="to.disabled"/>' +
      '</div>' +
    '</div>'
})
export class FormattedDateTimeInputComponent extends FieldType {

  constructor(private dmyDateTimePipe: Dmy_datetimePipe) {
    super();
  }

  get value() {
    return get(this.model, this.key);
  }

  format(v) {
    return this.dmyDateTimePipe.transform(v);
  }

  get label() {
    return this.to.label;
  }

}

