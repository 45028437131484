/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncomeAndExpenseEntry } from './incomeAndExpenseEntry';


export interface IncomeAndExpenseListResult { 
    entries?: Array<IncomeAndExpenseEntry>;
    /**
     * The net amount of all income and expense entries provided in the result
     */
    net_contribution?: number;
}

