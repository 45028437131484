/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StatementEntryType } from './statementEntryType';


export interface ContractStatementEntry { 
    id?: string;
    /**
     * The date on which the transaction was executed
     */
    entry_date?: string;
    /**
     * Friendly description of the statement transaction entry
     */
    description?: string;
    /**
     * The document number supporting the statement transaction entry (if available)
     */
    document_number?: string;
    /**
     * The amount involved in this transaction
     */
    amount?: number;
    /**
     * The balance value after the current transaction was executed
     */
    balance_after?: number;
    type?: StatementEntryType;
}

