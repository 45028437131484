/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AuditLogSortField } from './auditLogSortField';
import { SortOrder } from './sortOrder';


export interface AuditLogFilter { 
    /**
     * The page number of the result (the first page is 0)
     */
    page_number?: number;
    /**
     * The number of items returned in each page
     */
    page_size?: number;
    sort_field?: AuditLogSortField;
    sort_order?: SortOrder;
    id?: string;
    credential_id?: string;
    entity_id?: string;
    entity_types?: Array<string>;
    types?: Array<string>;
    partial_summary?: string;
    creation_timestamp_from?: string;
    creation_timestamp_to?: string;
}

