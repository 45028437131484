/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReservationGuestDetails { 
    name?: string;
    surname?: string;
    email?: string;
    phone?: string;
    skype?: string;
    passport_no?: string;
    address?: string;
    zipcode?: string;
    country?: string;
    vat_number?: string;
    additional_comments?: string;
    customer_id?: string;
}

