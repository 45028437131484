/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DemandAnalysis { 
    /**
     * the date the demand has been analysed
     */
    date?: string;
    /**
     * the number of properties that are occupied on the analysed date
     */
    booked_properties?: number;
    /**
     * the total number of properties that are available (including those occupied) on the analysed date
     */
    available_properties?: number;
}

