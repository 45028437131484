/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractReservationSortField } from './contractReservationSortField';
import { SortOrder } from './sortOrder';


export interface ContractReservationFinancialFilter { 
    /**
     * The page number of the result (the first page is 0)
     */
    page_number?: number;
    /**
     * The number of items returned in each page
     */
    page_size?: number;
    sort_field?: ContractReservationSortField;
    sort_order?: SortOrder;
    reservation_id?: string;
    periodic_entry_id?: string;
    entry_date_from?: string;
    entry_date_to?: string;
    partial_contract_friendly_reference?: string;
    partial_property_friendly_reference?: string;
    partial_owner_friendly_reference?: string;
    owner_booking?: boolean;
    processed?: boolean;
}

