/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractState } from './contractState';
import { LandOwnerShortRef } from './landOwnerShortRef';
import { ContractType } from './contractType';
import { PropertyShortRef } from './propertyShortRef';


export interface ContractSummary { 
    id?: string;
    friendly_reference?: string;
    contract_type?: ContractType;
    state?: ContractState;
    owner_short_ref?: LandOwnerShortRef;
    manual_rating?: number;
    start_date?: string;
    end_date?: string;
    property_ids?: Array<string>;
    property_refs?: Array<PropertyShortRef>;
    created_timestamp?: string;
    last_updated?: string;
    document_name?: string;
}

