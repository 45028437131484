/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ZzzingServiceFulfilmentState = 'PENDING' | 'SCHEDULED' | 'PENDING_CANCELLATION' | 'CANCELLATION_ACTIONED';

export const ZzzingServiceFulfilmentState = {
    PENDING: 'PENDING' as ZzzingServiceFulfilmentState,
    SCHEDULED: 'SCHEDULED' as ZzzingServiceFulfilmentState,
    PENDINGCANCELLATION: 'PENDING_CANCELLATION' as ZzzingServiceFulfilmentState,
    CANCELLATIONACTIONED: 'CANCELLATION_ACTIONED' as ZzzingServiceFulfilmentState
};

