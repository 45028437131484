/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CheckInManualCode } from './checkInManualCode';


export interface CheckInDetails { 
    /**
     * The TTLock lock id
     */
    lock_id?: string;
    self_check_in?: boolean;
    use_manually_set_code?: boolean;
    email_check_in_instructions?: string;
    /**
     * Any key that needs to be pressed after the actual pin code e.g. #
     */
    pin_code_suffix?: string;
    manual_codes?: Array<CheckInManualCode>;
}

