<div class="col-12 d-flex flex-column justify-content-center align-items-center h-75">
  <div class="boxes-loading">
    <div class="box-loading">
      <!-- divs represent the top, left, right and shadow of the box-->
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="box-loading">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="box-loading">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="box-loading">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
