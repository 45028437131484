import {AfterViewInit, Component, OnInit, Renderer2} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {LangService} from './shared/lang.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  isMultiColorActive = false;

  constructor(private langService: LangService,
              private renderer: Renderer2,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private titleService: Title) {

  }

  ngOnInit() {
    this.langService.init();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe(data => {
        const actualTitle = data?.title ? 'Zzzing Admin | ' + data.title : 'Zzzing Admin';
        this.titleService.setTitle(actualTitle);
      });
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.renderer.addClass(document.body, 'show');
    }, 1000);
    setTimeout(() => {
      this.renderer.addClass(document.body, 'default-transition');
    }, 1500);
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

}
