/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractShortRef } from './contractShortRef';
import { ContractStatementEntry } from './contractStatementEntry';


export interface ContractStatement { 
    contract_id?: string;
    contract_short_ref?: ContractShortRef;
    /**
     * The start date of the statement
     */
    date_from?: string;
    /**
     * The end date of the statement
     */
    date_to?: string;
    currency?: string;
    opening_balance?: number;
    current_balance?: number;
    entries?: Array<ContractStatementEntry>;
}

