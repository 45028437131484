/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CommercialDocument } from './commercialDocument';
import { ReservationPaymentStatus } from './reservationPaymentStatus';
import { FeeEntry } from './feeEntry';


export interface ReservationFinancialDetails { 
    guest_price?: number;
    paid_amount?: number;
    channel_manager_price?: number;
    fees?: Array<FeeEntry>;
    costs?: Array<FeeEntry>;
    payment_status?: ReservationPaymentStatus;
    /**
     * The commercial documents related to this booking
     */
    commercial_documents?: Array<CommercialDocument>;
}

