/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractState } from './contractState';
import { ContractType } from './contractType';


export interface ContractFilter { 
    /**
     * The page number of the result (the first page is 0)
     */
    page_number?: number;
    /**
     * The number of items returned in each page
     */
    page_size?: number;
    /**
     * The friendly reference of the contract
     */
    partial_contract_friendly_reference?: string;
    property_id?: string;
    state?: ContractState;
    states?: Array<ContractState>;
    /**
     * The friendly reference of the property
     */
    partial_property_friendly_reference?: string;
    /**
     * The external ID of the property (e.g. from rentals united)
     */
    property_external_id?: string;
    type?: ContractType;
    owner_id?: string;
    /**
     * The friendly reference of the owner
     */
    partial_owner_friendly_reference?: string;
    /**
     * The end date of the contract
     */
    end_date_from?: string;
    /**
     * The end date of the contract
     */
    end_date_to?: string;
}

