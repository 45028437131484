<div class="modal-body text-center">
  <h6 class="modal-title mb-4">Change Password</h6>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="container">
      <formly-form
        [form]="form"
        [fields]="fields"
        [model]="model"
        [options]="options">
      </formly-form>
    </div>
    <div class="d-flex justify-content-center">
      <button type="submit" class="m-2 btn btn-primary" [disabled]="!form.valid">Save</button>
      <button type="button" class="m-2 btn btn-outline-secondary" (click)="cancel()">Cancel</button>
    </div>
  </form>
</div>
