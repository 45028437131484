import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'formly-field-typeahead',
  styleUrls: ['./typehead.component.scss'],
  templateUrl: './typehead.component.html'
})
export class TypeheadComponent extends FieldType implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
  typeaheadEmitter$ = new EventEmitter<string>();
  options$;

  getLabel(data) {
    if (this.to.itemLabelProp) {
      return data[this.to.itemLabelProp];
    }
    return typeof this.to.labelProp === 'function' ? this.to.labelProp(data) : data[this.to.labelProp];
  }

  onScrollToEnd() {
    if (this.to.onScrollEnd) {
      this.to.onScrollEnd();
    }
  }

  ngOnInit() {
    this.options$ = this.typeaheadEmitter$;
    if (this.to.onSearchPhrase) {
      this.options$ = this.options$.pipe(
        tap((v) => this.to.onSearchPhrase(v)),
      );
    }
    const startWithValue: string = this.to.startWith ? this.to.startWith(this.formControl.value) : '';
    this.options$ = this.options$.pipe(
      takeUntil(this.onDestroy$),
      startWith(startWithValue),
      filter(v => v !== null),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(this.to.search$),
    );

    this.options$.subscribe();
  }

  ngOnDestroy() {
    this.onDestroy$.complete();
  }

  compareWith() {
    if (this.to.compareWith) {
      return this.to.compareWith;
    }
    return (itemOption, selected) => {
      return itemOption[this.to.valueProp] === selected;
    };
  }

}
