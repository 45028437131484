import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ImagePlaceholderDirective, LazyLoadedImageComponent} from '@app/components/images/image-placeholder.directive';

@NgModule({
  declarations: [
    ImagePlaceholderDirective, LazyLoadedImageComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [],
  exports: [
    ImagePlaceholderDirective, LazyLoadedImageComponent
  ]
})

export class ImagePlaceholderModule { }
