import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
  selector: 'formly-zzzing-wrapper-form-field',
  templateUrl: 'zzzing-form-field-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyZzzingWrapperFormFieldComponent extends FieldWrapper {
}
