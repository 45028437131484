/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractShortRef } from './contractShortRef';
import { PropertyTag } from './propertyTag';
import { PropertyImage } from './propertyImage';
import { LandOwnerShortRef } from './landOwnerShortRef';
import { PropertyType } from './propertyType';
import { PropertyUnitType } from './propertyUnitType';
import { Room } from './room';
import { PropertyState } from './propertyState';


export interface PropertySummary { 
    id?: string;
    external_id?: string;
    state?: PropertyState;
    friendly_reference?: string;
    /**
     * A friendly reference provided by the landowners to identify the property for themselves
     */
    owner_property_reference?: string;
    name?: string;
    type?: PropertyType;
    address?: string;
    location?: string;
    grouping?: string;
    number_of_bedrooms?: number;
    number_of_bathrooms?: number;
    owner_short_ref?: LandOwnerShortRef;
    active_contract_short_ref?: ContractShortRef;
    linked_contracts_short_refs?: Array<ContractShortRef>;
    created_timestamp?: string;
    last_updated?: string;
    /**
     * The main image of the property as saved in rentals united
     */
    image_url?: string;
    /**
     * List of property image URLs and the relevant captions
     */
    images?: Array<PropertyImage>;
    /**
     * Boolean whether all required basic information about the property has been provided
     */
    property_details_provided?: boolean;
    /**
     * Boolean whether the property has been linked to a contract
     */
    linking_done?: boolean;
    /**
     * The number of available units that can be booked in this property
     */
    number_of_units?: number;
    building_id?: string;
    building_name?: string;
    property_unit_type?: PropertyUnitType;
    rooms?: Array<Room>;
    tags?: Array<PropertyTag>;
}

