import { environment } from 'src/environments/environment';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
}

const data: IMenuItem[] = [
  {
    icon: 'iconsminds-monitor-analytics',
    label: 'menu.dashboards.root',
    to: `${adminRoot}/dashboards`,
    subs: [
      {
        icon: 'iconsminds-bar-chart-4',
        label: 'menu.dashboards.performance-dashboard',
        to: `${adminRoot}/dashboards/performance-dashboard`,
      },
      {
        icon: 'iconsminds-statistic',
        label: 'menu.dashboards.revenue-management',
        to: `${adminRoot}/dashboards/revenue-management-dashboard`,
      },
      {
        icon: 'iconsminds-target',
        label: 'menu.dashboards.budget-analysis-dashboard',
        to: `${adminRoot}/dashboards/budget-analysis-dashboard`,
      },
      {
        icon: 'iconsminds-check',
        label: 'menu.dashboards.operations-dashboard',
        to: `${adminRoot}/dashboards/operations-dashboard`,
      }
    ],
  },
  {
    icon: 'iconsminds-shop-2',
    label: 'menu.inventory.root',
    to: `${adminRoot}/inventory`,
    subs: [
      {
        icon: 'iconsminds-home',
        label: 'menu.inventory.properties',
        to: `${adminRoot}/inventory/properties`,
      },
      {
        icon: 'iconsminds-building',
        label: 'menu.inventory.buildings',
        to: `${adminRoot}/inventory/buildings`,
      },
      {
        icon: 'iconsminds-business-mens',
        label: 'menu.inventory.landowners',
        to: `${adminRoot}/inventory/landowners`,
      },
      {
        icon: 'iconsminds-paper',
        label: 'menu.inventory.contracts',
        to: `${adminRoot}/inventory/contracts`,
      },
    ],
  },
  {
    icon: 'iconsminds-hotel',
    label: 'menu.reservations.root',
    to: `${adminRoot}/reservations`,
    subs: [
      {
        icon: 'iconsminds-home',
        label: 'menu.reservations.reservations',
        to: `${adminRoot}/reservations/guest-reservations`,
      },
      {
        icon: 'iconsminds-clock-forward',
        label: 'menu.reservations.long-lets',
        to: `${adminRoot}/reservations/guest-long-lets`,
      },
      {
        icon: 'iconsminds-mens',
        label: 'menu.reservations.guests',
        to: `${adminRoot}/reservations/guests`,
      },
      {
        icon: 'iconsminds-email',
        label: 'menu.reservations.guest-communications',
        to: `${adminRoot}/reservations/guest-communications`,
      },
      {
        icon: 'iconsminds-notepad',
        label: 'menu.reservations.guest-issues',
        to: `${adminRoot}/reservations/guest-issues`,
      },
      {
        icon: 'iconsminds-euro',
        label: 'menu.reservations.sales-channels',
        to: `${adminRoot}/reservations/sales-channels`,
      },
      {
        icon: 'iconsminds-money-bag',
        label: 'menu.reservations.reservation-contribution',
        to: `${adminRoot}/reservations/reservation-contribution`,
      }
    ],
  },
  {
    icon: 'iconsminds-tag',
    label: 'menu.guest-services.root',
    to: `${adminRoot}/guest-services`,
    subs: [
      {
        icon: 'iconsminds-museum',
        label: 'menu.guest-services.bokun-services',
        to: `${adminRoot}/guest-services/bokun-services`,
      },
      {
        icon: 'iconsminds-pricing',
        label: 'menu.guest-services.zzzing-services',
        to: `${adminRoot}/guest-services/zzzing-services`,
      },
      {
        icon: 'ml-1 mr-2 pr-1 simple-icon-emotsmile',
        label: 'menu.guest-services.guest-services-bookings',
        to: `${adminRoot}/guest-services/guest-services-bookings`,
      },
      {
        icon: 'ml-1 mr-2 pr-1 fa-z lh-1',
        label: 'menu.guest-services.guest-zzzing-services-bookings',
        to: `${adminRoot}/guest-services/guest-zzzing-services-bookings`,
      }
    ],
  },
  {
    icon: 'iconsminds-wrench',
    label: 'menu.platform.root',
    to: `${adminRoot}/platform`,
    subs: [
      {
        icon: 'iconsminds-data-center',
        label: 'menu.platform.audit-logs',
        to: `${adminRoot}/platform/audit-logs`
      },
      {
        icon: 'iconsminds-euro',
        label: 'menu.platform.sales-channel-mappings',
        to: `${adminRoot}/platform/sales-channel-mappings`
      },
      {
        icon: 'iconsminds-arrow-right-2',
        label: 'menu.platform.account-mappings',
        to: `${adminRoot}/platform/account-mappings`
      },
      {
        icon: 'iconsminds-arrow-inside-45',
        label: 'menu.platform.tax-mappings',
        to: `${adminRoot}/platform/tax-mappings`
      }
    ]
  }

];
export default data;
