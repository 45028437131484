import {Component, OnInit} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'formly-field-typeahead',
  templateUrl: './slider-form.component.html'
})
export class SliderFormComponent extends FieldType implements OnInit {

  ngOnInit(): void {
    if (this.formControl.value == null) {
      this.formControl.setValue(1);
    }
  }

}
