/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FinancialPeriodicStatistic } from './financialPeriodicStatistic';
import { ReservationPeriodicStatistic } from './reservationPeriodicStatistic';


export interface PeriodicPerformance { 
    /**
     * The collection of rent income (gross rent + tourist tax) statistics per period
     */
    rent_income_statistics?: Array<FinancialPeriodicStatistic>;
    /**
     * The collection of average occupancy statistics per period
     */
    average_occupancy_statistics?: Array<ReservationPeriodicStatistic>;
    /**
     * The collection of average night price statistics per period
     */
    average_night_price_statistics?: Array<ReservationPeriodicStatistic>;
}

