/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AccountingLedgerType = 'PAYABLE' | 'RECEIVABLE' | 'GENERAL';

export const AccountingLedgerType = {
    PAYABLE: 'PAYABLE' as AccountingLedgerType,
    RECEIVABLE: 'RECEIVABLE' as AccountingLedgerType,
    GENERAL: 'GENERAL' as AccountingLedgerType
};

