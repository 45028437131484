/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The field to sort the filtered customers by
 */
export type CustomerSortField = 'ID' | 'LAST_UPDATED' | 'NAME' | 'REGISTRATION_TIMESTAMP';

export const CustomerSortField = {
    ID: 'ID' as CustomerSortField,
    LASTUPDATED: 'LAST_UPDATED' as CustomerSortField,
    NAME: 'NAME' as CustomerSortField,
    REGISTRATIONTIMESTAMP: 'REGISTRATION_TIMESTAMP' as CustomerSortField
};

