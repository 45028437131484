/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncomeAndExpenseEntryType } from './incomeAndExpenseEntryType';


export interface ContractIncomeExpensesFilter { 
    /**
     * Filtering on entry date (from)
     */
    date_from?: string;
    /**
     * Filtering on entry date (to)
     */
    date_to?: string;
    /**
     * The first day of the month to filter by billing period month.  This is irrespective of the entry date but more on the associated billing period.
     */
    month?: string;
    statement_entry_id?: string;
    entry_type?: IncomeAndExpenseEntryType;
}

