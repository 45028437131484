/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReservationState } from './reservationState';
import { ReservationShortRef } from './reservationShortRef';


export interface ReservationDetails { 
    short_ref?: ReservationShortRef;
    booking_ref?: string;
    check_in_date?: string;
    check_out_date?: string;
    state?: ReservationState;
    owner_booking?: boolean;
}

