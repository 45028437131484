/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReservationState } from './reservationState';
import { ReservationFinancialDetails } from './reservationFinancialDetails';
import { BasicLandOwnerShortRef } from './basicLandOwnerShortRef';
import { PropertyShortRef } from './propertyShortRef';
import { ReservationGuestDetails } from './reservationGuestDetails';
import { ReservationPinDetails } from './reservationPinDetails';
import { LinkedReservationDocumentReference } from './linkedReservationDocumentReference';
import { LongLetReservationDetails } from './longLetReservationDetails';
import { ReservationGuestIssueDetails } from './reservationGuestIssueDetails';
import { ReservationType } from './reservationType';
import { ReservationStatement } from './reservationStatement';
import { BasicContractShortRef } from './basicContractShortRef';
import { Room } from './room';


export interface Reservation { 
    id?: string;
    type?: ReservationType;
    booking_ref?: string;
    channel_manager_id?: string;
    channel_manager_key?: string;
    channel_manager_last_updated?: string;
    /**
     * The reservation id given by the sales channel
     */
    sales_channel_reservation_id?: string;
    /**
     * The stay id given by the sales channel in case of multiple stays for a single reservation
     */
    stay_id?: string;
    sales_channel_id?: string;
    sales_channel_name?: string;
    journey_id?: string;
    state?: ReservationState;
    property_short_ref?: PropertyShortRef;
    contract_short_ref?: BasicContractShortRef;
    owner_short_ref?: BasicLandOwnerShortRef;
    booking_date?: string;
    cancellation_date?: string;
    check_in_date?: string;
    check_in_time?: string;
    check_out_date?: string;
    number_of_nights?: number;
    number_of_guests?: number;
    comments?: string;
    owner_booking?: boolean;
    guest_details?: ReservationGuestDetails;
    financial_details?: ReservationFinancialDetails;
    pin_details?: ReservationPinDetails;
    custom_fields?: { [key: string]: string; };
    rooms?: Array<Room>;
    long_let_details?: LongLetReservationDetails;
    /**
     * The linked documents related to this reservation
     */
    linked_documents?: Array<LinkedReservationDocumentReference>;
    statement?: ReservationStatement;
    /**
     * The list of reported reservation guest issues
     */
    guest_issues?: Array<ReservationGuestIssueDetails>;
    personal_notes?: string;
}

