/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReservationAverageLeadTime { 
    /**
     * the date/month the reservations will be checking in
     */
    date?: string;
    /**
     * the average lead time for the bookings checking in on \"date\"
     */
    average_lead_time?: number;
}

