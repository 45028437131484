/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LandOwnerState } from './landOwnerState';
import { LandOwnerType } from './landOwnerType';
import { LandOwnerContactDetails } from './landOwnerContactDetails';


export interface LandOwnerSummary { 
    id?: string;
    friendly_reference?: string;
    type?: LandOwnerType;
    name?: string;
    manual_rating?: number;
    number_of_linked_contracts?: number;
    number_of_linked_properties?: number;
    contact_details?: LandOwnerContactDetails;
    created_timestamp?: string;
    last_updated?: string;
    state?: LandOwnerState;
}

