import {Component, HostListener, Renderer2} from '@angular/core';
import {getThemeColor, getThemeRadius, setThemeColor, setThemeRadius} from 'src/app/utils/util';

@Component({
  selector: 'app-color-switcher',
  templateUrl: './color-switcher.component.html',
})
export class ColorSwitcherComponent {
  colors: string[] = [
    'bluenavy',
    'blueyale',
    'blueolympic',
    'greenmoss',
    'greenlime',
    'purplemonster',
    'orangecarrot',
    'redruby',
    'yellowgranola',
    'greysteel',
  ];
  selectedColor: string = getThemeColor();
  isOpenSwitcher = false;
  toggleClass = 'theme-colors';
  radius = getThemeRadius();

  constructor(private renderer: Renderer2) {
    this.changeRadius(this.radius);
  }

  changeColor(color: string) {
    this.selectedColor = color;
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  toggleSwitcher(event) {
    this.isOpenSwitcher = !this.isOpenSwitcher;
    this.toggleClass = this.isOpenSwitcher
      ? 'theme-colors shown'
      : 'theme-colors hidden';
    event.stopPropagation();
  }

  changeRadius(radius) {
    if (radius === 'flat') {
      this.renderer.removeClass(document.body, 'rounded');
    } else {
      this.renderer.addClass(document.body, 'rounded');
    }
    setThemeRadius(radius);
  }

  @HostListener('document:click')
  handleDocumentClick() {
    this.isOpenSwitcher = false;
    this.toggleClass = 'theme-colors hidden';
  }
}
