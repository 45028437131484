/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReservationFilter } from './reservationFilter';


export interface ReservationDocumentExportFilter { 
    reservation_filter?: ReservationFilter;
    issuer_reference_type?: ReservationDocumentExportFilter.IssuerReferenceTypeEnum;
    issuer_reference?: string;
}
export namespace ReservationDocumentExportFilter {
    export type IssuerReferenceTypeEnum = 'OWNER' | 'SYSTEM';
    export const IssuerReferenceTypeEnum = {
        OWNER: 'OWNER' as IssuerReferenceTypeEnum,
        SYSTEM: 'SYSTEM' as IssuerReferenceTypeEnum
    };
}


