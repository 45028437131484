/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncomeAndExpenseEntryType } from './incomeAndExpenseEntryType';


export interface IncomeAndExpenseEntry { 
    id?: string;
    statement_entry_id?: string;
    /**
     * The date on which the transaction was executed
     */
    entry_date?: string;
    /**
     * The first day of the month of the linked billing period. Intended to be formatted as \'MMMM yyyy\'
     */
    month?: string;
    /**
     * Friendly description of the transaction entry
     */
    description?: string;
    /**
     * The document number supporting the income/expense e.g. invoice (if available)
     */
    document_number?: string;
    /**
     * The amount involved in this transaction
     */
    amount?: number;
    entry_type?: IncomeAndExpenseEntryType;
}

