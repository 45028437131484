/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BasicLandOwnerShortRef } from './basicLandOwnerShortRef';
import { BasicContractShortRef } from './basicContractShortRef';
import { PeriodicContractFinancialCostType } from './periodicContractFinancialCostType';


export interface PeriodicContractFinancial { 
    id?: string;
    entry_date?: string;
    contract_short_ref?: BasicContractShortRef;
    owner_short_ref?: BasicLandOwnerShortRef;
    cost_type?: PeriodicContractFinancialCostType;
    billing_period_start_date?: string;
    billing_period_end_date?: string;
    aggregate_gross_rent?: number;
    aggregate_tourist_tax?: number;
    aggregate_ota_fee_rent?: number;
    aggregate_ota_fee_tourist_tax?: number;
    aggregate_payment_charge?: number;
    aggregate_vh_service_charge?: number;
    aggregate_receivables?: number;
    aggregate_payables?: number;
    aggregate_owner_share?: number;
    processed_timestamp?: string;
    invoice_number?: string;
}

