// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://dev.zzzing.com/api/admin',
  adminRoot: '/app',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 2500, // this is set to a high width so the sub menu will only stick on extremely large screens
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  defaultColor: 'light.zzzing',
  isDarkSwitchActive: false,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  strapi_base_url: 'https://strapi.dev.zzzing.com',

  bokun_widget_script: 'https://widgets.bokuntest.com/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=e69a5aa1-83f6-4d88-8523-863cf5b229b3',
  bokun_airport_transfer_widget: 'https://widgets.bokuntest.com/online-sales/e69a5aa1-83f6-4d88-8523-863cf5b229b3/product-list/564',
  bokun_tour_widget: 'https://widgets.bokuntest.com/online-sales/e69a5aa1-83f6-4d88-8523-863cf5b229b3/product-list/565'
};
