/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SortOrder } from './sortOrder';
import { PeriodicContractFinancialCostType } from './periodicContractFinancialCostType';
import { PeriodicContractFinancialSortField } from './periodicContractFinancialSortField';


export interface PeriodicContractFinancialFilter { 
    /**
     * The page number of the result (the first page is 0)
     */
    page_number?: number;
    /**
     * The number of items returned in each page
     */
    page_size?: number;
    sort_field?: PeriodicContractFinancialSortField;
    sort_order?: SortOrder;
    contract_id?: string;
    periodic_entry_id?: string;
    entry_date_from?: string;
    entry_date_to?: string;
    billing_period_from?: string;
    billing_period_to?: string;
    cost_type?: PeriodicContractFinancialCostType;
    partial_contract_friendly_reference?: string;
    partial_owner_friendly_reference?: string;
    processed?: boolean;
}

