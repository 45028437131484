/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type of the service booked. For example, tours, taxi, airport transfer
 */
export type GuestServiceType = 'TOUR' | 'AIRPORT_TRANSFER' | 'TAXI' | 'ZZZING_SERVICE' | 'ACCOM_DIRECT';

export const GuestServiceType = {
    TOUR: 'TOUR' as GuestServiceType,
    AIRPORTTRANSFER: 'AIRPORT_TRANSFER' as GuestServiceType,
    TAXI: 'TAXI' as GuestServiceType,
    ZZZINGSERVICE: 'ZZZING_SERVICE' as GuestServiceType,
    ACCOMDIRECT: 'ACCOM_DIRECT' as GuestServiceType
};

