/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerReferenceType } from './customerReferenceType';
import { EmailState } from './emailState';


export interface EmailSummary { 
    id?: string;
    timestamp_created?: string;
    state?: EmailState;
    incoming?: boolean;
    communication_type?: string;
    customer_reference_type?: CustomerReferenceType;
    customer_reference?: string;
    sender_email?: string;
    recipient_email?: string;
    subject?: string;
}

