/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractBillingCycle } from './contractBillingCycle';


export interface ServiceManagementBillingTerms { 
    billing_cycle?: ContractBillingCycle;
    /**
     * Service management fee percentage
     */
    service_management_fee_percentage?: number;
    /**
     * Long Let service management fee percentage
     */
    long_let_service_management_fee_percentage?: number;
}

