import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

export const DATE_FORMAT = 'dd-MM-yyyy';
export const MOMENT_DATE_FORMAT = 'DD-MM-YYYY';

/**
 * A pipe to be used to format a date in 'dd-MM-yyyy' format.
 */
@Pipe({
  name: 'dmy_date'
})
export class Dmy_datePipe implements PipeTransform {

  private static DATE_FORMAT = 'dd-MM-yyyy';

  constructor(private datePipe: DatePipe) {}

  transform(value: any): string {
    return value ? this.datePipe.transform(new Date(value), Dmy_datePipe.DATE_FORMAT) : null;
  }

}
