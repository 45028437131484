/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ZzzingServiceSimplePricing } from './zzzingServiceSimplePricing';
import { ZzzingServiceAdvancedPricing } from './zzzingServiceAdvancedPricing';
import { PricingType } from './pricingType';


/**
 * The pricing structure for a Zzzing service
 */
export interface ZzzingServicePricing { 
    pricing_type?: PricingType;
    simple_pricing?: ZzzingServiceSimplePricing;
    advanced_pricing?: ZzzingServiceAdvancedPricing;
}

