/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The credential role
 */
export type AccessRole = 'ADMIN' | 'LANDOWNER' | 'GUEST' | 'SYSTEM';

export const AccessRole = {
    ADMIN: 'ADMIN' as AccessRole,
    LANDOWNER: 'LANDOWNER' as AccessRole,
    GUEST: 'GUEST' as AccessRole,
    SYSTEM: 'SYSTEM' as AccessRole
};

