<div>
  <label *ngIf="hasDescription()">{{ to.description }}</label>

  <div *ngFor="let field of field.fieldGroup; let i = index;" class="py-2">

    <div class="row align-items-center">
      <p *ngIf="to.showIndex">{{i + 1}}</p>
      <formly-field [field]="field" class="col"></formly-field>
      <div class="col-1 text-left d-flex align-items-center" *ngIf="!to.disabled">
        <div>
          <a (click)="remove(i)" class="text-danger"><i class='fa fa-trash-alt'></i></a>
        </div>
      </div>

    </div>
    <div class="row m-2 status-incorrect" [hidden]="to.hideFieldErrors"
      *ngFor="let err of field.formControl.errors | keyvalue"> {{ err.value.message  }}</div>

    <div class="separator pt-3"></div>

  </div>

  <div class="d-flex flex-row mt-4">
    <div class="col-10">
      <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
    </div>
    <div class="col-2 text-right">
      <button class="btn btn-primary" type="button" (click)="add()" *ngIf="!to.disabled">
        <i class='fa fa-plus'></i>
      </button>
    </div>
  </div>

</div>
