import {Component} from '@angular/core';
import {ValueComponent} from '@forms/primitives/value/value.component';

@Component({
  selector: 'formly-link-value',
  template: '<a [routerLink]="getRoute()">  {{ format(value) }} </a>',
})
export class LinkValueComponent extends ValueComponent {
  getRoute() {
    return typeof this.to.route === 'function' ? this.to.route(this.model) : '';
  }
}
