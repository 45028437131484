/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReservationShortRef { 
    id?: string;
    /**
     * The reservation id given by the channel manager
     */
    channel_manager_reservation_id?: string;
    /**
     * The reservation id given by the sales channel
     */
    sales_channel_reservation_id?: string;
    /**
     * The name of the guest
     */
    guest_name?: string;
}

