/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CommercialDocument } from './commercialDocument';
import { GuestServiceBookingItemSummary } from './guestServiceBookingItemSummary';
import { GuestServiceType } from './guestServiceType';
import { GuestServiceBookingStatus } from './guestServiceBookingStatus';
import { GuestServiceBookingCancellationInfo } from './guestServiceBookingCancellationInfo';


export interface GuestServiceBookingSummary { 
    id?: string;
    confirmation_code?: string;
    customer_id?: string;
    /**
     * The name of the customer that bought the service
     */
    customer_name?: string;
    creation_date?: string;
    service_date?: string;
    start_time?: string;
    time_zone?: string;
    duration?: string;
    service_type?: GuestServiceType;
    total_price?: number;
    description?: string;
    state?: GuestServiceBookingStatus;
    items_summary?: Array<GuestServiceBookingItemSummary>;
    cancellation_info?: GuestServiceBookingCancellationInfo;
    /**
     * Optional url for an image that represents the booking
     */
    image_url?: string;
    /**
     * The commercial documents related to this booking
     */
    commercial_documents?: Array<CommercialDocument>;
}

