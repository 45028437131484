import {Injectable} from '@angular/core';
import {LoginSuccessResult} from '@api/model/loginSuccessResult';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {AUTH_TOKEN_KEY, createSession} from './session.model';

export interface SessionState extends EntityState<LoginSuccessResult> {
}

export const USER_KEY = 'user';
export const USER_ID_KEY = 'user_id';
export const PICTURE_KEY = 'pictureUrl';

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'Session'})
export class SessionStore extends EntityStore<SessionState> {

  constructor() {
    super(createSession({
      access_token: localStorage.getItem(AUTH_TOKEN_KEY),
      user: {
        id: localStorage.getItem(USER_ID_KEY),
        username: localStorage.getItem(USER_KEY),
        profile_picture_url: localStorage.getItem(PICTURE_KEY),
      }
    }));
  }

  login(session: SessionState) {
    localStorage.setItem(AUTH_TOKEN_KEY, session.access_token);
    localStorage.setItem(USER_KEY, session.user?.username);
    localStorage.setItem(USER_ID_KEY, session.user?.id);
    localStorage.setItem(PICTURE_KEY, session.user?.profile_picture_url);
    this.update(session);
  }

  logout() {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(USER_ID_KEY);
    localStorage.removeItem(PICTURE_KEY);
    this.update(createSession({}));
  }
}

