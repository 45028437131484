/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LandOwnerPayeeDetails } from './landOwnerPayeeDetails';
import { LegalDocumentType } from './legalDocumentType';
import { Address } from './address';
import { LandOwnerType } from './landOwnerType';
import { LandOwnerContactDetails } from './landOwnerContactDetails';


export interface LandOwnerRegisterParams { 
    friendly_reference: string;
    type: LandOwnerType;
    name: string;
    legal_address: Address;
    manual_rating?: number;
    legal_document_type: LegalDocumentType;
    legal_document_reference: string;
    contact_details: LandOwnerContactDetails;
    payee_details: LandOwnerPayeeDetails;
    custom_notes?: string;
}

