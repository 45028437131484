import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-warning',
  template: '<div class="d-flex align-items-center text-danger mb-2">' +
    '<i class="fas fa-exclamation-triangle pr-1"></i>' +
    '<span>{{warning}}</span>' +
    '</div>'
})
export class WarningComponent extends FieldType {
  constructor() {
    super();
  }

  get warning() {
    return this.to.warning;
  }
}
