import {Component} from '@angular/core';
import {ValueComponent} from '@forms/primitives/value/value.component';

@Component({
  selector: 'formly-labelled-router-link',
  template: '<div class="form-group"><label>{{label}}</label>' +
    '<div class="py-2">' +
    '<a *ngIf="showAsLink()" [routerLink]="routerLink"> {{ format(value) }} </a>' +
    '<div *ngIf="!showAsLink()"> {{ defaultText }} </div>' +
    '</div>' +
    '</div>'
})
export class LabelledRouterLinkValueComponent extends ValueComponent {
  public showAsLink() {
    return !!this.value;
  }

  get label() {
    return this.to.label;
  }

  get defaultText() {
    return this.to.defaultText;
  }

  format(v): any {
    if (this.to.valueText) {
      return this.to.valueText(this.model);
    } else {
      return super.format(v);
    }
  }

  get routerLink() {
    if (this.to.routerLink) {
      return this.to.routerLink(this.model);
    } else {
      return [];
    }
  }

}
