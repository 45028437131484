import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {latLng, LeafletMouseEvent, Map, marker, Marker, tileLayer} from 'leaflet';

@Component({
  selector: 'formly-coordinates-type',
  templateUrl: './coordinates.component.html',
  styleUrls: ['./coordinates.component.scss']
})
export class CoordinatesComponent extends FieldType {

  mapOptions = {
    zoom: 17,
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 18, attribution: '...'})
    ],
    center: latLng(46.879966, -121.726909),
    markers: []
  };

  private map: Map;
  private marker: Marker;
  f;

  /**
   * Put marker on map onClick event
   */
  onClick(event: LeafletMouseEvent) {
    if (this.to.disabled || this.to.readonly) {
      return;
    }
    const {latlng: {lat: latitude, lng: longitude}} = event;
    this.formControl.patchValue({longitude, latitude});
  }

  /**
   * Captures raw map object
   */
  onMapReady(map: Map) {
    this.map = map;
    if (this.formControl.value) {
      this.onModelChange(this.formControl.value);
    }
    if (this.to.disabled || this.to.readonly) {
      this.disable();
    }
    this.formControl.valueChanges.subscribe(this.onModelChange.bind(this));
  }

  /**
   * When model updates, sets marker in proper position on map
   */
  onModelChange({longitude: lng, latitude: lat}: any = {}) {
    if (!this.map || !lng || !lat) {
      return;
    }

    if (this.marker) {
      this.map.removeLayer(this.marker);
    }
    this.marker = marker([lat, lng]);
    if (this.to.description) {
      this.marker.bindPopup(this.to.description);
    }
    this.map.panTo({lng, lat});
    this.map.addLayer(this.marker);
  }

  disable() {
    this.map.keyboard.disable();
  }

}

