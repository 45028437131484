import {DatePipe} from '@angular/common';
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {API_DATE_FORMAT} from '@app/api.config.factory';
import {FieldType} from '@ngx-formly/core';
import {get} from 'lodash';
import * as moment from 'moment';
import {BsDatepickerDirective, BsDaterangepickerDirective} from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'formly-date-type',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [ ],
})
export class DatepickerComponent extends FieldType implements OnInit {

  @ViewChild(BsDatepickerDirective, { static: false }) datepicker: BsDatepickerDirective;
  @ViewChild(BsDaterangepickerDirective, { static: false }) daterangepicker: BsDaterangepickerDirective;

  dateRangeValue: Date[] = null;

  constructor(private datePipe: DatePipe,
              private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    if (this.to.dateRangePicker) {
      this.initializeDateRangePicker();
    } else {
      this.initializeDatePicker();
    }
  }

  initializeDateRangePicker() {
    const fromDate = this.model[this.to.fromKey];
    const toDate = this.model[this.to.toKey];
    if (fromDate && toDate) {
      this.getDateRangeFrom().setValue(fromDate);
      this.getDateRangeTo().setValue(toDate);
      this.dateRangeValue = [new Date(fromDate), new Date(toDate)];
    }

    this.getDateRangeFrom().valueChanges.subscribe(value => this.handleFormValueChange(value));
    this.getDateRangeTo().valueChanges.subscribe(value => this.handleFormValueChange(value));
  }

  initializeDatePicker() {
    const date = get(this.model, this.key);
    if (date) {
      this.formControl.setValue(date);
    }

    this.formControl.valueChanges.subscribe(value => this.handleFormValueChange(value));
  }

  handleFormValueChange(value) {
    this.changeDetectorRef.detectChanges(); // to detect changes external to this component
    if (this.to.dateRangePicker && !value) {
      this.dateRangeValue = null;
    }
  }

  onValueChange($date: any) {
    if (this.datepicker) {
      if ($date) {
        // By default, the date picked would be at 00:00 but in the user's locale (e.g. CET).
        // The actual value we set in the form will be in UTC
        const dateValueWithoutZone = moment($date).utc(true).toDate();
        this.formControl.markAsDirty();
        this.formControl.setValue(this.formatDateForApi(dateValueWithoutZone), {emitModelToViewChange: false});
      }
    }
  }

  onDateRangeValueChange($dates: any) {
    if (this.daterangepicker) {
      if ($dates) {
        // By default, the date picked would be at 00:00 but in the user's locale (e.g. CET).
        // The actual value we set in the form will be in UTC
        const fromDate = $dates[0];
        const fromDateValueWithoutZone = moment(fromDate).utc(true).toDate();
        const toDate = $dates[1];
        const toDateValueWithoutZone = moment(toDate).utc(true).toDate();

        const fromDateFormControl = this.getDateRangeFrom();
        fromDateFormControl.markAsDirty();
        fromDateFormControl.setValue(this.formatDateForApi(fromDateValueWithoutZone), {emitModelToViewChange: false});

        const toDateFormControl = this.getDateRangeTo();
        toDateFormControl.markAsDirty();
        toDateFormControl.setValue(this.formatDateForApi(toDateValueWithoutZone), {emitModelToViewChange: false});
      }
    }
  }

  getDate(): Date {
    const date = this.formControl.value;
    return date ? new Date(date) : null;
  }

  get minDate(): Date {
    return this.to.minDate;
  }

  get maxDate(): Date {
    return this.to.maxDate;
  }

  formatDateForApi(date): string {
    return this.datePipe.transform(date, API_DATE_FORMAT);
  }

  emptyValue(): void {
    if (this.to.dateRangePicker) {
      this.getDateRangeFrom().setValue(null);
      this.getDateRangeTo().setValue(null);
      this.dateRangeValue = null;
    } else {
      this.formControl.setValue(null);
    }
  }

  hasValue() {
    if (this.to.dateRangePicker) {
      return this.getDateRangeFrom().value && this.getDateRangeTo().value;
    } else {
      return this.formControl.value;
    }
  }

  private getDateRangeFrom() {
    return this.formControl.get(this.to.fromKey);
  }

  private getDateRangeTo() {
    return this.formControl.get(this.to.toKey);
  }
}
