import {Component, Directive, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';

@Component({
  selector: 'app-img',
  template: `
    <div>
      <div class="image-loader" [ngStyle]="getLoaderStyle()">Loading ...</div>
      <img [src]="src" [ngStyle]="getImageStyle()" [alt]="alt" (loaded)="onLoaded($event)"/>
    </div>`
})
export class LazyLoadedImageComponent {
  @Input() src: string;

  @Input() alt: string;

  loaded = false;

  onLoaded(isLoaded) {
    this.loaded = isLoaded;
  }

  getImageStyle() {
    return !this.loaded ? {display: 'none'} : {};
  }

  getLoaderStyle() {
    return this.loaded ? {display: 'none'} : {};
  }
}

/**
 * When shared module is imported in destination module,
 * all image tags would be extended to have placeholder in case loading image fails
 */
@Directive({
  selector: '[appSafeImage]',
})
export class ImagePlaceholderDirective {

  @HostBinding('src')
  @Input() src: string;

  @Output() loaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('load')
  onLoad() {
    this.loaded.emit(true);
  }

  @HostListener('error')
  onError() {
    this.src = './assets/img/not-available.png';
    this.loaded.emit(true);
  }

}
