import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormlyFieldSelect} from '@ngx-formly/bootstrap';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'formly-enum-type',
  templateUrl: './enum.component.html',
  styleUrls: [],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnumComponent extends FormlyFieldSelect implements OnInit {

  ngOnInit() {
    if (this.to.defaultValue && !this.formControl.value) {
      this.formControl.setValue(this.to.defaultValue);
    } else if (!!this.formControl.value && this.to.multiple) {
      if (!(this.formControl.value instanceof Array)) {
        this.formControl.setValue([this.formControl.value]);
      }
    }
  }

  get opts(): Observable<any[]> {
    if (this.to.options instanceof Observable) {
      return this.to.options;
    } else {
      return of(this.to.options);
    }
  }

  get clearable(): boolean {
    return this.to.clearable ?? true;
  }

}
