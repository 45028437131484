/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The reservation rental refund source
 */
export type ReservationRefundSource = 'RENT' | 'CLEANING' | 'FULL_REFUND';

export const ReservationRefundSource = {
    RENT: 'RENT' as ReservationRefundSource,
    CLEANING: 'CLEANING' as ReservationRefundSource,
    FULLREFUND: 'FULL_REFUND' as ReservationRefundSource
};

