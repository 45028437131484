/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReservationDailyHighlight } from './reservationDailyHighlight';


export interface ReservationHighlights { 
    /**
     * the number of confirmed bookings made today
     */
    booked_today?: number;
    /**
     * the number of confirmed bookings made yesterday
     */
    booked_yesterday?: number;
    /**
     * the number of bookings cancelled today
     */
    cancelled_today?: number;
    /**
     * the number of bookings cancelled yesterday
     */
    cancelled_yesterday?: number;
    booked_weekly?: Array<ReservationDailyHighlight>;
    cancelled_weekly?: Array<ReservationDailyHighlight>;
}

