/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReservationPaymentState } from './reservationPaymentState';
import { ReservationPaymentMethodType } from './reservationPaymentMethodType';


export interface ReservationPaymentDetails { 
    payment_state?: ReservationPaymentState;
    payment_method_type?: ReservationPaymentMethodType;
    amount?: number;
    scheduled_timestamp?: string;
    expiry_timestamp?: string;
    next_retry_timestamp?: string;
}

