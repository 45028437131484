/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PropertyFilter } from './propertyFilter';


export interface FinancialPerformanceFilter { 
    /**
     * The start date of the period from which the financial performance will be extracted
     */
    date_from: string;
    /**
     * The end date of the period from which the financial performance will be extracted
     */
    date_to: string;
    property_filter?: PropertyFilter;
}

