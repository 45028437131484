/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractPaymentType } from './contractPaymentType';


export interface CreateContractPaymentInfoParams { 
    payment_reference: string;
    payment_date: string;
    /**
     * The amount that was paid to/from the landowner
     */
    amount: number;
    description: string;
    payment_type: ContractPaymentType;
}

