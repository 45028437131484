/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LandOwnerPaymentType } from './landOwnerPaymentType';
import { Address } from './address';
import { BankAccountDetails } from './bankAccountDetails';


export interface LandOwnerPayeeDetails { 
    payment_type?: LandOwnerPaymentType;
    beneficiary_name?: string;
    billing_address?: Address;
    bank_account_details?: BankAccountDetails;
    payment_instructions?: string;
    vat_number?: string;
    vat_exempt?: boolean;
    exo_number?: string;
}

