import {Injectable} from '@angular/core';
import {AuthService as ApiAuthService} from '@api/api/auth.service';
import {AdminLoginParams} from '@api/model/adminLoginParams';
import {LoginSuccessResult} from '@api/model/loginSuccessResult';
import {ZzzingAdminUser} from '@api/model/zzzingAdminUser';
import {SessionQuery} from '@app/shared/auth/session/session.query';
import {SessionStore} from '@app/shared/auth/session/session.store';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

export interface ISignInCredentials {
  email: string;
  password: string;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
}

export interface IPasswordReset {
  code: string;
  newPassword: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private sessionStore: SessionStore,
    private client: ApiAuthService,
    private query: SessionQuery) {
  }

  async signIn(credentials: ISignInCredentials): Promise<LoginSuccessResult> {
    return this.client.loginAdmin({username: credentials.email, password: credentials.password} as AdminLoginParams).pipe(tap(session => {
      this.sessionStore.login(session);
      return session;
    })).toPromise();
  }

  signOut() {
    this.sessionStore.logout();
  }

  sendPasswordEmail(email: string): Promise<any> {
    return this.client.forgotPasswordAdmin({ username: email }).toPromise();
  }

  getUser(): Observable<ZzzingAdminUser> {
    return this.query.user$;
  }

  isLoggedIn(): Observable<boolean> {
    return this.query.isLoggedIn$;
  }
}
