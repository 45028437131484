import {Component} from '@angular/core';
import {ValueComponent} from '@forms/primitives/value/value.component';

@Component({
  selector: 'formly-percent-value',
  template: '<div> {{ format(value) }} </div>',
})
export class PercentValueComponent extends ValueComponent {
  format(v) {
    return `${(parseFloat(v) * 100).toFixed(2)}%`;
  }
}
