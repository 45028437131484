import {HashLocationStrategy, LocationStrategy} from '@angular/common';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule, SecurityContext} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApolloGraphqlConfig} from '@app/apollographql.config.factory';
import {AppErrorHandler} from '@app/app.errorhandler';
import {BootstrapModule} from '@app/components/bootstrap/bootstrap.module';
import {AuthInterceptor} from '@app/shared/auth/auth.interceptor';
import {environment} from '@env/environment';
import {TranslateModule} from '@ngx-translate/core';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {MarkdownModule, MarkedOptions} from 'ngx-markdown';
import {ApiModule} from '../gen/api';
import {ApiConfigFactory} from './api.config.factory';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing';
import {LayoutContainersModule} from './containers/layout/layout.containers.module';
import {ViewsModule} from './views/views.module';

const MARKED_DEFAULT_OPTIONS = {
  provide: MarkedOptions,
  useValue: {
    baseUrl: environment.strapi_base_url
  } as MarkedOptions
};

@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ApiModule.forRoot(ApiConfigFactory),
    SimpleNotificationsModule.forRoot({timeOut: 5000, preventDuplicates: true}),
    BootstrapModule,
    MarkdownModule.forRoot({
      markedOptions: MARKED_DEFAULT_OPTIONS,
      sanitize: SecurityContext.NONE
    })
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: ErrorHandler, useClass: AppErrorHandler},
    {provide: APOLLO_OPTIONS, useFactory: ApolloGraphqlConfig, deps: [HttpLink]},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
