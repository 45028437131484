/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RentalEntityType } from './rentalEntityType';


export interface LongLetReservationDetails { 
    rental_entity_type?: RentalEntityType;
    rent_per_month_including_vat?: number;
    agency_fee_including_vat?: number;
    deposit?: number;
    next_rent_due_date?: string;
}

