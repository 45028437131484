/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ContractType = 'COMMISSION_BASED' | 'ARBITRAGE' | 'SERVICE_MANAGEMENT';

export const ContractType = {
    COMMISSIONBASED: 'COMMISSION_BASED' as ContractType,
    ARBITRAGE: 'ARBITRAGE' as ContractType,
    SERVICEMANAGEMENT: 'SERVICE_MANAGEMENT' as ContractType
};

