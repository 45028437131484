/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PropertyMaintenanceExpenseType } from './propertyMaintenanceExpenseType';
import { CreatePropertyMaintenanceExpenseItem } from './createPropertyMaintenanceExpenseItem';


export interface CreatePropertyMaintenanceExpenseEntryParams { 
    /**
     * The date on which the transaction was executed
     */
    entry_date: string;
    /**
     * The document number supporting the expense e.g. invoice (if available)
     */
    document_number: string;
    /**
     * An additional optional external reference that may be attached to the invoice e.g. job id
     */
    external_reference?: string;
    property_maintenance_type: PropertyMaintenanceExpenseType;
    sales_transaction_id?: string;
    property_id?: string;
    items: Array<CreatePropertyMaintenanceExpenseItem>;
}

