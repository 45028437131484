/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReservationDetails } from './reservationDetails';
import { RoomAllocation } from './roomAllocation';


export interface PropertyCalendarEntry { 
    /**
     * The date in the calendar
     */
    date?: string;
    property_id?: string;
    reservation?: ReservationDetails;
    room_allocation?: RoomAllocation;
}

