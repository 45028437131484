import {AbstractControl, ValidationErrors} from '@angular/forms';
import {get} from 'lodash';

export function minlengthValidationMessages(err, field) {
  return `Should have at least ${field.templateOptions.minLength} characters`;
}

export function fieldMatchValidator(control: AbstractControl) {
  const {new_password, new_password_retype} = control.value;
  // avoid displaying the message error when values are empty
  if (!new_password_retype || !new_password) {
    return null;
  }

  if (new_password_retype === new_password) {
    return null;
  }

  return {fieldMatch: {message: 'Password Not Matching'}};
}

export function fieldDateRangeValidator(control: AbstractControl, field, options): ValidationErrors {
  const {startDateKey, endDateKey} = options;
  const startDate = new Date(get(control.value, startDateKey));
  const endDate = new Date(get(control.value, endDateKey));
  if (startDate.getTime() < endDate.getTime()) {
    return null;
  }

  return {dateRange: {message: 'Date range is invalid.'}};

}
