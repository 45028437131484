/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LegalDocumentType = 'ID_CARD' | 'PASSPORT' | 'COMPANY_REGISTRATION_NUMBER';

export const LegalDocumentType = {
    IDCARD: 'ID_CARD' as LegalDocumentType,
    PASSPORT: 'PASSPORT' as LegalDocumentType,
    COMPANYREGISTRATIONNUMBER: 'COMPANY_REGISTRATION_NUMBER' as LegalDocumentType
};

