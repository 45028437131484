import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@app/shared/auth/auth.guard';
import {environment} from 'src/environments/environment';
import {ErrorComponent} from './error/error.component';

const adminRoot = environment.adminRoot.substr(1); // path cannot start with a slash

const routes: Routes = [
  {
    path: '',
    redirectTo: adminRoot,
    pathMatch: 'full',
  },
  {
    path: adminRoot,
    loadChildren: () => import('./app/app.module').then(m => m.AppModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {path: 'error', component: ErrorComponent, data: {title: 'Error'}},
  {path: '**', redirectTo: '/error'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ViewRoutingModule {
}
