/**
 * Zzzing Admin API
 * Zzzing Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ci@zzzing.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceManagementBillingTerms } from './serviceManagementBillingTerms';
import { ContractRightOfUse } from './contractRightOfUse';


export interface ServiceManagementContractDetails { 
    billing_terms?: ServiceManagementBillingTerms;
    /**
     * Expected yield by the owner
     */
    owner_yield_expectations?: number;
    right_of_use_terms?: ContractRightOfUse;
}

